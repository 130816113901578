/* App.css */
/* Estilos para el contenido principal de tu página */
.App {
    /* ... Otros estilos si los tienes ... */
    position: relative;
  }
  
  /* Estilos personalizados para el modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    position: relative;
    border-radius: 8px;
    text-align: -webkit-right;
    width: 70%;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-button-close {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background: white;
    width: 40px;
    height: 40px;
  }

 /*  .carousel-image {
    cursor: pointer;
  } */

  @media screen and (max-width: 500px) {
    .slick-prev::before {
      left: -35px !important;
    }

    .slick-next::before {
      right: -5px !important;
    }
  }