@media (max-width: 450px) {

    /* //CabeceraContratante// */
    .padding-top-tags-cabecera {
        margin-top: 31px !important;
    }

    .w-25 {
        width: 25%;
    }

    .aseguradoAdicionalesStyle {
        background-color: #F2FAFC !important;
        padding: 5%;
    }

    .bg-comparacion-planes-mobile {
        background-color: #f5f5f5;
    }

    .bg-white-comparacion-planes-mobile {
        background-color: #fff;
        padding: 1rem;
    }

    .cont-inputs-comparar {
        background-color: #fff;
        border-radius: 8px;
    }

    .padding-img-aseguradora-comparacion {
        padding: 4% 12% 0%;
    }

    .pl-4-mobile {
        padding-left: 4%;
    }

    .pr-costo-n {
        padding-inline-start: 38%;
    }

    .pl-5-mobile {
        padding-left: 15%;
    }

    .pr-cambiar {
        padding-inline-start: 32%;
    }

    .pr-5-mobile {
        padding-right: 5% !important;
    }

    .m-2-0-mobile {

        margin: 2% 0%;

    }

    .m-5-mobile {
        margin: 5% !important;
    }

    .mb-4-mobile {
        margin-bottom: 4% !important;
    }

    .ml-6-mobile {
        margin-left: 6%;
    }

    .ml-17-mobile {
        margin-left: 17% !important;
    }

    .ml-26-mobile {
        margin-left: 26%;
    }

    .ml-35-mobile {
        margin-left: 35%;
    }

    .ml-40-mobile {
        margin-left: 40%;
    }

    .ml-48-mobile {
        margin-left: 48%;
    }

    .mr-10-mobile {
        margin-right: 10% !important;
    }

    .pt-20px-mobile {
        padding-top: 20px !important;
    }

    .pt-2-mobile {
        padding-top: 2%;
    }

    .pt-15px-mobile {
        padding-top: 15px !important;
    }

    .display-flex-mobile {
        display: flex;
    }

    .titulo-conoce-mas-planes-mobile {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        margin: 16% !important;
    }

    .p-conoce-mas-planes-mobile {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin: 2% 9%;
    }

    .txt-ca {
        font-size: 15px;
        text-align: left;
        color: #8f8f8f;
        margin: 10% 5% 1% 10%;
    }

    .txt-cdad-anual {
        font-size: 20px;
        text-align: left;
        color: #212121;
    }

    .txt-msi {
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #424242;
    }

    .contenido-tarjetas {
        display: flex !important;
        width: 365px;
        height: 255px;
        position: relative;
        margin-left: 7% !important;
        margin-top: 10%;
    }

    .btn-elegir {
        position: absolute;
        left: -15px;
        top: -50px;
        width: 152px;
        height: 56px;
        border-radius: 8px;
    }

    .display-block-mobile {
        display: block !important;
    }

    .hideOnMobile {
        display: none !important;
    }

    .img-aseguradoras {
        width: 70%;
        height: 50%;
        margin: 1%;
    }

    .w-55-mobile {
        width: 55%;
    }

    .position-img-aseguradora-comparacion {
        position: absolute;
        top: 29%;
        left: 80%;
    }

    .position-txt-aseguradora-comparacion {
        position: absolute;
        top: 35%;
        left: 80%;
    }

    .vl-amarilla-titulo-comparar {
        border: 4.15px solid #ffd60e;
        border-bottom: none;
        border-top: none;
        border-left: none;
        height: 38px;
        margin-top: 5% !important;
        margin-right: 0%;
        margin-left: 4%;
    }

    .vl-tarjetas {
        height: 95px !important;
        margin-top: 3% !important;
        margin-right: 25px !important;
    }

    .vl-titulo {
        margin: 1% 4% !important;
        height: 85px !important;
    }

    .w-90 {
        width: 90%;
    }

    .txt-2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }

    .txt-listo-titulo {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-align: left;
    }

    .p-comparar-beneficios-aseguradoras {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: #8f8f8f;
        margin: 0 0 12px 0;
    }

    .titulo-comparar-beneficios-aeguradoras {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin: 0;
    }

    .txt-comparar-cifras-beneficios {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
    }

    .align-left {
        justify-content: start;
    }

    .align-right {
        justify-content: end;
    }

    .txt-costo-anual-comparar {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #8f8f8f;
    }

    .txt-cifra-costo-anual-comparar {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-align: left;
    }

    .bnt-loQuiero-comparar {
        width: 150px !important;
        height: 50px !important;
        background-color: #039ecc !important;
        color: #fff !important;
    }

    .contenedorAvisosImportantes {
        width: 75% !important;
        height: 140px !important;
        margin-top: 5% !important;
   
    }

    .contenidoAvisosImportantes {
        padding: 0px 35px !important;
        font-size: 13px !important;
    }

    .tituloAvisosImportantes {
        text-align: center;
        font-size: 15px;
    }

    .espaciadoAvisos {
        height: 120px !important;
    }
}

@media (min-width: 800px) {
    .showOnlyOnMobile {
        display: none !important;
    }

    .aseguradoAdicionalesStyle {
        background-color: #e8f6fa !important;
        min-height: 57px;
        padding-top: 15px;
    }
}


@media (min-width: 425px) {
    @media (max-width: 769px) {
        .mediasQuerysGuias {
            background-color: red !important;
        }

        .hideOnTablet {
            display: none !important;
        }

        .mt-31-tags {
            margin-top: 0%;
        }

        .showOntablet {
            display: flex;
        }

        .with-332-paper {
            width: 332px;
        }

        .paddingLeft-tarjetas {
            padding-left: 7px;
        }

        .displayNoneTablet {
            display: none;
        }

        .display-block-tablet {
            display: block !important;
        }

        .padding-tarjetas-tablet {
            padding: 0% !important;
        }

        .w-235-tablet {
            width: 235px !important;
        }

        .with-304-tablet {
            width: 304px !important;
        }

        .jutify-content-sw {
            justify-content: space-between !important;
        }

        .bg-white-tablet {
            background-color: #fff !important;
            padding: 2%;
            border-radius: 8px;
        }
    }
}
@media (max-width: 359px)  {
    .btn-ec-mobile {
        background-color: #fff;
        border: 2px solid #039ECC;
    }
}
@media (min-width: 391px) and (max-width: 424) {
    .btn-ec-mobile {
        background-color: #fff;
        border: 2px solid #039ECC;
    }
}


@media (max-width: 424px) {
    .mediasQuerysGuias {
        background-color: yellow !important;
    }
    .espaciosTitularCabecera{
        margin:30px 0 0 0 !important
    }

    .mt-31-tags {
        margin-top: 31px;
    }

    .marginRight-8px {
        margin-right: 8px;
    }

    .marginLeft-8px {
        margin-left: 8px;
    }

    .btn-ec-mobile {
        border-radius: 8px;
        height: 48px;
        padding: 1%;
        text-align: center;
        border: #039ECC solid 1px;
        background-color: #FFF;
    }

    .flex-end-on-desktop {
        display: flex;
        justify-content: flex-end;
      }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hideOnMobile-2 {
        display: none;
    }

    .showOnlyOnMobile-2 {
        display: block !important;
    }

    .mr-8-mobile {
        margin-right: 8% !important;
    }

    .pt-21-modal {
        padding-top: 21%;
    }

    .bg-cabecera-mobile {
        background-color: #F4F4F4 !important;
        justify-content: center !important;
    }
}

.quotation-details-container {
    background-color: #F2FAFC;
    border-radius: 6px;
    justify-content: center;
    padding: 1rem 0;
}

@media (max-width: 360px) {
    .quotation-details-container {
        background-color: transparent;
    }

    .flex-center-on-mobile {
        justify-content: center;
    }
}

@media (min-width: 770px) {
    .mediasQuerysGuias {
        background-color: blue;
    }

    .mt-31-tags {
        margin-top: 31px;
    }

    .hideOnDesktop {
        display: none;
    }
}



.espaciosCabeceraTitular {
    padding:24px 0 20px 37px 
}

.espaciadoAvisos {
    height: 140px;
    margin-bottom: 3%;
}

.tituloAvisosImportantes {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    margin-top: 10px;
}

.contenidoAvisosImportantes {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding: 0px 20px;
}

.contenedorAvisosImportantes {
    background-color: rgb(242, 250, 252);
    color: #8f8f8f;
    border-radius: 6px;
    width: 90%;
    height: 150px;
    margin-top: 15%;
    padding: 0 0 3% 0;   
}

@media(max-width: 799px){
    .etiqueta-beneficios-adicionales{
        margin-left:0 !important;
    }
}

.texto-etiqueta-banderin {
    font-size: 15px;
}

.cabecera-coberturas {
    height: 210px;
}

.align-self-end {
    align-self: end;
}

.ico-tooltip {
    max-width: 30px;
}

.img-aseguradoras {
    width: 80%;
    height: 60px;
    margin: 1%;
}

.container-planes {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 2%;
    /*top: -650px*/
}

.txt-cdad-anual {
    font-size: 20px;
    font-weight: 700;
    color: #212121;
}

.mw-container {
    max-width: 1140px !important;
}

.mw-contenido-paper {
    max-width: 220px !important;
}

.mw-contenido-coberturas {
    max-width: 280px !important;
    align-self: self-end;
    margin-bottom: 40px !important;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-2 {
    margin-bottom: 2%;
}

.p-7 {
    padding: 7%;
}

.p-5 {
    padding: 5%;
}

.p-1 {
    padding: 1%;
}

.p-11 {
    padding: 11%;
}

.pl-8 {
    padding-left: 8%;
}

.pl-10 {
    padding-left: 10%;
}

.pl-11 {
    padding-left: 11%;
}

.pl-28 {
    padding-left: 28%;
}

.pl-31 {
    padding-left: 31%;
}

.pl-33 {
    padding-left: 33%;
}

.pl-50 {
    padding-left: 50%;
}

.pl-32 {
    padding-left: 32%;
}

.mt-1r {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2% !important;
}

.mt-4 {
    margin-top: 4% !important;
}

.mt-14 {
    margin-top: 14%;
}

.mt-15 {
    margin-top: 14%;
}

.mt-20 {
    margin-top: 20%;
}

.mr-30 {
    margin-right: 30%;
}

.ml-12 {
    margin-left: 12% !important;
}

.ml-60 {
    margin-left: 60% !important;
}

.ml-90 {
    margin-left: 90%;
}

.space-between-centered {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.txt-2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4px;
}

.txt-resultados {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #039ecc;
    margin-top: 20%;
}

.txt-titular {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.txt-info-titular {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.txt-informacion-cliente {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.txt-coberturas {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.txt-principal-cobertura {
    margin-top: 100%;
    margin-bottom: 28%;
}

.txt-ca {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
}

.txt-msi {
    display: none;
}

.txt-cdad1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: center;
}

.txt-cdad2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-cdad3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-porsentaje {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.text-center {
    text-align: center;
}

.text-lowercase {
    text-transform: lowercase;
}

.font-weight-700 {
    font-weight: 700;
}

.txt-cdad4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-cdad5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-pd {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-nhc {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.contenido-tarjetas {
    width: 200px;
    border-radius: 6px;
    margin: 5% 0 5% 0;
}

.cont-bnts {
    z-index: 10;
}

.btn-elegir {
    height: 48px;
    width: 180px;
    border-radius: 10px;
    padding: 16px;
    background-color: #039ecc !important;
    color: #fff;
}

.btn-comparar {
    height: 48px;
    width: 180px;
    border-radius: 10px;
    background-color: #fff !important;
    color: #039ecc !important;
}

.hiddeOnMobile {
    display: block !important;
}

.regresar-opciones {
    text-decoration: none;
    color: #039ecc;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.color-azul-a {
    color: #039ecc;
}

.btn-compartir-mobile {
    width: 100%;
    height: 56px;
    background-color: #f5fbfd;
    padding: 0;
    border-radius: 0%;
    margin-bottom: 5%;
}

.txt-principal-modal {
    text-align: center;
}

.input-fecha-modal {
    width: 450px !important;
}

.btn-cancelar-modal {
    width: 30%;
    height: 48px;
    border-radius: 10px;
    color: #039ecc;
    border: 1px solid #039ecc !important;
    background-color: #fff;
}

.btn-guardar-modal {
    width: 30%;
    height: 48px;
    border-radius: 10px;
    border: none !important;
    background-color: #039ecc !important;
    color: #fff !important;
}

.tooltips {
    height: 95px;
    width: 428px;
    border-radius: 8px;
    background-color: #ebf8fb;
}

.vl-titulo {
    border: 4.15px solid #ffd60e;
    border-bottom: none;
    border-top: none;
    border-left: none;
    height: 49px;
    margin-top: 3% !important;
    margin-right: 1%;
}

.bnt-enviarCotizacion {
    padding: 0;
    margin-bottom: 1%;
}

.btn-nuevaCotizacion {
    border: 1px solid #039ecc;
    color: #039ecc;
    background-color: #fff;
    border-radius: 10px;
}

.a-btn-irDC {
    text-decoration: none;
    color: #fff;
}

.btn-cancelar-modalNC {
    height: 48px;
    width: 151px;
    border-radius: 10px;
    padding: 16px;
    background-color: #fff !important;
    color: #039ecc !important;
    border: 1px solid #039ecc !important;
    box-shadow: none !important;
}

.btn-aceptar-modalNC {
    height: 48px;
    width: 151px;
    border-radius: 10px;
    padding: 16px;
    background-color: #039ecc !important;
    color: #fff !important;
    border: 1px hidden #039ecc !important;
    box-shadow: none !important;
}

.m-btn-modalNC {
    margin: 5% 5% 0% 4%;
}

.p-modalNC {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.titulo-modalNC {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.25px;
    text-align: left;
}

.btn-enviar-modalEC {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    background-color: #039ecc !important;
    color: #fff !important;
    border: 1px hidden #039ecc !important;
    box-shadow: none !important;
    text-transform: uppercase;
}

.m-btn-modalEC {
    margin: 10% 5% 0% 4%;
}

.styleTextAseguradosAdicionales {
    font-weight: 600;
}

.horizontalDividerAsegurados {
    left: 23.19%;
    right: 23.33%;
    top: 32.67%;
    bottom: 67.33%;
    opacity: 0.4;
    border-bottom: 1px solid #595959;
}

.txt-contenido-asegurados-adicionales {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.txt-titulo-asegurados-adicionales {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.paper-mobile {
    max-width: 400px;
    height: 100%;
    margin: 0 2% 0 2%;
    margin-top: 5%;

}

.margen-paper-mobile {
    padding-left: 10%;
    padding-top: 5%;
}

.divider-paper-mobile {
    margin-left: -5% !important;
    height: 94px !important;
    margin-top: -8px !important;
}

.margin-btns-mobile {
    margin: 1% 0% !important;
    padding-left: 4% !important;
}

.btn-comparar-mobile {
    width: 136px;
    height: 56px;
    border-radius: 8px !important;
    color: #039ecc !important;
    border: 1px solid #039ecc !important;
}

.btn-loQuiero-mobile {
    width: 136px;
    height: 56px;
    border-radius: 8px !important;
    background-color: #039ecc !important;
    border: none;
    color: #fff !important;
}

.btn-nueva-cotizacion-mobile {
    width: 344px;
    height: 48px;
    border-radius: 8px !important;
    color: #039ecc !important;
    border: 1px solid #039ecc !important;
    background-color: #fff !important;
    margin: 5% 0% !important;
}

.txt-cdad-planes {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: right !important;
}

.pt-2 {
    padding-top: 1.5rem;
}

.titulo-compara-beneficios {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-left: 12px;
}

.divider {
    width: 144px;
    border: 1px solid #c7c7c7;
}

.tarjetas-tamano {
    height: 645px;
}

.mw-19-cabezera {
    max-width: 19% !important;
}

.marginTop-152 {
    margin-top: 152px;
}