.pregunta-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  letter-spacing: 0.25px;

  /* Black */

  color: #212121;
}

.contenido-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Black */

  color: #212121;
}
