/*Cabecera Inicio*/
.logo-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* White */
  color: #ffffff;
}

div.coberturas-adicionales__container {
  border-radius: 8px;
  border: 2px solid #77CAE3;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.3);
}

div.coberturas-adicionales__container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: clamp(90px, 14vh, 160px);
  background-color: #C0E6F2;
  color: #039ECC;
  font-size: clamp(14px, 0.8rem, 18px);
  text-align: center;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 40px;
}

div.coberturas-adicionales__container > div:nth-child(even) {
  background-color: #039ECC;
  color: white;
}

h2.coberturas-adicionales__title {
  font-size: clamp(20px, 5vw, 28px);
  font-weight: 700;
  text-align: center;
  width: 80%;
}

img.coberturas__img {
  object-fit: cover;
  width: clamp(313px, 100%, 560px);
}

h2.coberturas-title {
  font-weight: 700;
  font-size: clamp(20px, 5vw, 28px);
}

ul.coberturas-adicionales-list > li {
  font-weight: 400;
  font-size: clamp(18px, 4vw, 20px);
  margin-bottom: 24px;
}

ul.coberturas-adicionales-list > li::marker {
  font-size: 10px;
  
}

.main-title {
  font-size: 50px;
  line-height: 39px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  margin: 5% 0%;
}

.text-h4-white {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
  color: #ffffff;
}

.container-form-size {
  height: 535px;
  width: 461px;
}

.centrado-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnFrameNCInicioHabilitado {
  background: #FFFFFF;
  cursor: pointer;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  width: 50%;
  padding: 3%;
  box-shadow: rgb(99 99 99 / 10%) 0px -4px 4px 0px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.btnFrameInicioNCDeshabilitado {
  background: #F4F4F4;
  cursor: pointer;
  width: 38%;
  border-top-left-radius: 8px;
  padding: 3%;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.btnFrameYTUCInicioDeshabilitado {
  background: #F4F4F4;
  cursor: pointer;
  width: 63%;
  border-top-right-radius: 8px;
  padding: 3%;
  color: #8F8F8F;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.btnFrameYTUCInicioHabilitado {
  background: #FFFFFF;
  cursor: pointer;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  width: 50%;
  padding: 3%;
  box-shadow: rgb(99 99 99 / 10%) 0px -4px 4px 0px;

  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.padding-formulario-inicio {
  padding-left: 10%;
}

.espacio-divisor-secciones {
  display: none;
  z-index: 0;
  background-color: #f4f4f4;
}

/*Tarjetas Informativas Inicio*/
.main-title-cards-info {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #212121;
}

.title-cards-blue {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 5%;

  color: #039ecc;
}

.titulo-box-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #039ecc;
  margin-bottom: 12px;
}
.desc-box-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}
.titulo-plans {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
}

.desc-plans {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}


/*Mobile*/
/*XSsmall*/
@media (min-width: 0px) and (max-width: 359px) {
  .espacio-divisor-secciones {
    display: flex;
    height: 550px;
  }
}

/*Small*/
@media (min-width: 360px) and (max-width: 389px) {

  .espacio-divisor-secciones {
    display: flex;
    height: 170px;
  }
}

/*Medium*/
@media (min-width: 390px) and (max-width: 424px) {
  .espacio-divisor-secciones {
    display: flex;
    height: 250px;
  }
}

/*Tablet*/
@media (min-width: 425px) and (max-width: 767px) {

  .espacio-divisor-secciones {
    display: flex;
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .alt {
    height: 220px;
  }
  .pad-pq-inter {
    padding: 20px 0px 20px 0px;
  }
  .pad-pq-inter-relleno {
    padding: 60px 0px 60px 0px;
  }
}

/*Mobile y Tablet*/
@media (min-width: 0px) and (max-width: 1023px) {
  .logo-text {
    font-size: 20px;
  }
  .main-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    letter-spacing: 0.5px;
  }
  .text-h4-white {
    font-size: 29px;
    line-height: 34.13px;
    font-weight: 700;
    margin: 0;
  }
}

.form-container {
  /* White */
  background: #ffffff;
  /* Elevation 01dp */
  box-shadow: 2px 4px 4px rgba(33, 33, 33, 0.04);
  border-radius: 8px;

  padding: 0px 1px 24px;
  width: 350px;
}


@media (min-width: 426px) and (max-width: 1024px) {
  .padding-formulario-inicio {
    padding: 1rem;
    margin-top: 59px;
  }
}
@media (min-width: 425px) and (max-width: 1023px) {
  .btnFrameNCInicioHabilitado,
  .btnFrameInicioNCDeshabilitado,
  .btnFrameYTUCInicioDeshabilitado,
  .btnFrameYTUCInicioHabilitado {
    padding: 2%;
    font-size: 14px;
  }
  .text-h4-white {
    font-size: 28px;
  }
}

@media (min-width: 0px) and (max-width: 425px) {
  .btnFrameNCInicioHabilitado,
  .btnFrameInicioNCDeshabilitado,
  .btnFrameYTUCInicioDeshabilitado,
  .btnFrameYTUCInicioHabilitado {
    padding: 2%;
    font-size: 14px;
  }

  .padding-formulario-inicio {
    padding: 1rem;
    margin-top: 59px;
  }
}

@media (max-width: 450px) {
  .form-inicio-mobile-position {
    position: absolute;
    z-index: 2;
    left: 10px;

  }

  .with-box-form {
    width: 369px;
  }
}

@media (max-width: 380px) {
  .form-container {
    width: 300px;
  }
}

@media (max-width: 360px) {
  .form-container {
    padding: 12px;
  }
}

.mb-15 {
  margin-bottom: 1.5rem;
}

.form-titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

/* input[type="text"] {
  width:10em;
} */


.logo-seguro {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: 1px;
  color: #ffffff;
}

.texto-logo {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.texto-logo-seguro {
  font-size: 60px;
  line-height: 39px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  margin: 5% 0%;
}

@media (max-width: 450px) {
  .texto-logo-seguro {
    font-size: 40px;
  }
}

@media (max-width: 450px) {
  .texto-logo {
    font-size: 28px;
  }
}

.cinturon {
  height: 80px;
  background: #ffffff;
}

.cinturon-text {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* Black */

  color: #212121;
}

.landing-body {
  background: white;
}

.titulo-seccion {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #212121;
}

.title-plan-section {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #212121;
  text-transform: uppercase;
}

.plan-para-ti-text {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
}

@media (max-width: 450px) {
  .titulo-seccion {
    font-size: 28px;
    font-weight: 700;
    line-height: 34.13px;
    text-align: center;
  }
}

.subtitulo-seccion {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
}

.box {
  padding: 16px 0;
  border-radius: 8px;
  min-height: 180px;
  max-height: 100%;
  max-width: 100%;
  background: #ffffff;
}

.accordion-item {
  min-height: 180px;
  max-height: 100%;
  max-width: 100%;
  background: #ffffff;
}

.box-pq-inter {
  padding: 0px 10px;
}

@media (max-width: 425px) {
  .accordion-item {
    min-height: 0px;
    padding: 16px 0px;
  }

  .box-pq-inter{
    padding: 0px 10px;
  }
}


.tiendas {
  display: flex;
  justify-content: space-around;
}

.tiendas-movil {
  display: flex;
  justify-content: center;
  padding-top: 16px;
}

.plans {
  padding: 15px;
  width: auto;
}

.algo-mas {
  border: 1px solid #77cae3;
  box-sizing: border-box;
  border-radius: 16px;
  min-width: 150px;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.slider-gmm {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.algo-mas-movil {
  border: 1px solid #77cae3;
  box-sizing: border-box;
  border-radius: 16px;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: 8px;
  margin-right: 8px;
}

.slider-animation {
  display: flex;
  width: 200px;
  position: relative;
  animation: sliderGmm 20s infinite;
}

#sliderGMM {
  animation-timing-function: linear;
}

@keyframes sliderGmm {
  from {
    left: 100%;
  }

  to {
    left: -400%;
  }
}

.algo-mas img {
  width: 115px;
}

/*Hospitales*/
.boxh {
  border-radius: 8px;
  height: 406px;
  max-width: 100%;

  background: #039ecc;
}

.texh {
  font-style: normal;
  line-height: 39px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;

  color: #fff;
}

.text-h-dos {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  color: #fff;
}

.text-h-tres {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
}

.plansSelec {
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
}

.boxPlan {
  position: absolute;
  width: 485px;
  height: 46px;
  left: 770px;
  top: 2163px;
}

/*FloatingButtons*/
.textfab {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.textfab2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #039ecc;
}

/*DudasInicioModal*/

.textDudas {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #424242;
}

.espacio {
  padding-top: 1.5rem;
}

.href-modal {
  padding-left: 0.5rem;
  color: #039ecc;
  cursor: pointer;
}

.button {
  background: #039ecc;
}

/*PreguntasFrecuentes*/

.textPF {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  padding-top: 5rem;

  color: #212121;
}

.linea {
  border-top: 1px solid black;
  height: 2px;
  max-width: 200px;
  padding: 0;
  margin: 20px auto 0 auto;
}

.texto-numero-cotizacion {
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* identical to box height, or 171% */

  display: flex !important;
  align-items: center !important;
  text-align: right;
  text-transform: none !important;
}

.pt-minus-0px {
  padding-top: 0px !important;
}

/*modal ayuda*/
.modal-titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-text-dos {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-tel {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.25px;

  /* Black */

  color: #212121;
}

.modal-bg {
  background: #f5f5f5;
  width: 100%;
}

.modal-individual-padding {
  padding: 20px 24px;
}

@media (min-width: 426px) {
  .hide-desktop {
    display: none !important;
  }
}

@media (min-width: 821px) {
  .hide-xl {
    display: none !important;
  }
}

@media (max-width: 769px) {
  .logo-seguro {
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    letter-spacing: 0.5px;
  }

  .form-container {
    padding: 24px;
    width: 320px;
  }
}
@media (max-width: 820px) {
  .hide-movil {
    display: none !important;
  }
}

@media (max-width: 426px) {
  .logo-seguro {
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    letter-spacing: 0.5px;
  }

  .texto-seguro {
    font-size: 28px;
    line-height: 34px;
  }

  .cinturon {
    display: none !important;
  }

  .modal-individual-padding {
    padding: 15px 18px;
  }
}

.comunica {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-transform: lowercase;
  /* identical to box height, or 100% */

  text-align: center;
  text-decoration-line: underline;

  /* Azul Inter */

  color: #039ecc;
}

.w100px {
  width: 70px;
}

.h70 {
  height: 70px;
}

.pr-16px {
  padding-right: 16px;
}

.pt-8px {
  padding-top: 8px;
}

.pt-13px {
  padding-top: 13px;
}

.h-55px {
  height: 55px;
}

.p16-form-inicio {
  padding: 8px 8px 0px 16px !important;
}

.tienes-cotizacion {
  padding: 8px 16px 0px 16px !important;
}

.form-inicio-btns-top {
  width: 398px;
  height: 50px;
}

.pb-24px {
  padding-bottom: 24px;
}

.boton-high-inicio {
  height: 55px;
}

.mb-inputs-inicio {
  margin-bottom: 6%;
}

.padding-box-inter-inicio {
  padding: 20px 0px 0px 0px !important;
}

.img-banner-desktop {
  width: 100%;
  border-radius: 8px;
  margin-top: 2%;
}

@media (max-width: 450px) {
  .padding-main-titulo-inicio {
    padding: 4px 30px !important;
  }
}

@media (max-width: 450px) {
  .mb-35-mobile-banner {
    margin-top: 35% !important;
  }
}

@media (max-width: 450px) {
  .mt-120-mobile-inter {
    margin-top: 120% !important;
  }
}

.planes-mobile-accordeon {
  margin: 5% 0%;
  border-radius: 8px !important;
}

.mb-5-divider-mobile {
  margin-bottom: 5% !important;
}

.showOnlyOnMobile {
  display: none;
}

@media (max-width: 598px) {
  .showOnlyOnMobile {
    display: block !important;
  }
}

.hideOnlyOnMobile {
  display: block;
}

@media (max-width: 599px) {
  .hideOnlyOnMobile {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .w-98-mobile {
    width: 98%;
  }
}

.pl-16-form {
  padding-left: 16px !important;
}

.pl-32-form {
  padding-left: 32px !important;
}

@media (min-width: 768px) and (max-width: 820px) {
  .pl-32-form {
    padding-left: 26px !important;
  }
}

.font-size-terminos-condiciones-form {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: left;
}

@media (min-width: 425px) {
  @media (max-width: 769px) {
    .margin-left-form-inicio {
      margin-left: -50px;
    }
  }
}

/* Seccion para el slide */

div.gallery-slider-container {
	margin-top: 48px;
	overflow-x: scroll;
	cursor: grab;
	scroll-behavior: smooth;
	width: clamp(380px, 95vw,1300px);
}

div.gallery-slider-container::-webkit-scrollbar {
	display: none;
}

.gallery-slider-container > .gallery-slider {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: fit-content;
	padding: 10px 10px;
}

.gallery-slider > .card:first-child {
	height: 370px;
	width: clamp(240px, 32vw,330px);
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	margin: 0 24px 0 42px;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-slider > .card {
	height: 370px;
	width: clamp(240px, 32vw,330px);
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	margin: 0 24px 0 0;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-slider > .card > .card-content > h3 {
	text-align: center;
}

.gallery-slider > .card > .card-content > p {
	text-align: center;
	font-size: clamp(12px, 1.5vw, 14px);
}

.gallery-slider > .card > .card-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.gallery-slider > .card > .card-image > img {
	width: clamp(160px, 25vw, 214px);
}

.gallery-slider > .card:nth-child(even) {
	background-color: rgba(244, 244, 244, 1);
}

@media (max-width:899px) {
  .coberturas-title {
    text-align: center;
  }
}

@media (min-width:650px) and (max-width:750px) {
  .coberturas-adicionales-list {
    padding-left: 80px !important;
  }
}

@media (min-width:751px) and (max-width:899px) {
  .coberturas-adicionales-list {
    padding-left: 120px !important;
  }
}

@media (min-width:900px) {
  .coberturas-title {
    text-align: center;
  }
}