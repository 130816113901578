.text-ya {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */
  letter-spacing: 0.5px;
  /* Text/H1 */
  color: #212121;
  margin-left: 17px;
}

.text-dos {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-left: 17px;
}

.linea-amarilla {
  position: relative;
}

.linea-amarilla img {
  left: 0;
  top: 0;
  position: absolute;
}

.linea-amarilla-dos {
  border-left-color: #ffd60e;
  border-left-style: solid;
  border-left-width: 5px;
}

.nombre {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* Text/H1 */
  color: #212121;
  margin-left: 17px;
}

.titular {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* Text/H1 */
  color: #212121;
  margin-left: 17px;
}

.fecha {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* Text/Body */
  color: #424242;
}

.frame-cuestionario {
  border-radius: 12px;
  height: 100%;
  width: 100%;
  box-shadow: 4px 6px 16px #c7c7c7;
  background-color: #ffffff;
}

.frame-cuestionario-detalle {
  padding: 25px 55px;
}

@media (max-width: 768px) {
  .frame-cuestionario-detalle {
    padding: 1rem 1.5rem;
  }
}

.padding-preguntas {
  padding-left: 28px;
  padding-right: 28px;
}

.text-preguntas {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
}

.text-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #000000;
}

@media (max-width: 426px) {
  .frame-cuestionario {
    box-shadow: none;
  }
  .padding-preguntas {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.background-acordeon {
  background-color: #c0e6f2 !important;
  border-radius: 0px !important;
}

.background-form-acordeon {
  background-color: #f2fafc !important;
  padding: 8px 16px;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}

button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.inline {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.no-margin-accordeon > .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.boton-mobile {
  width: 340px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}