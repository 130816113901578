.blue-bar {
  height: 51px;
  background: #041431;
  width: 100%;
  color: white;
  padding: 12px 40px
}

.box-flex-center {
  display: flex;
  align-items: center;
}

.blue-bar-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.blue-bar-subtext {
  font-style: normal;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 20px!important;
  color: #FFFFFF!important;
}

.cmb-w {
  width: 250px;
  padding-left: 7px;
}

.bkg-cmb-gray {
  background-color: #8F8F8F;
}

.bkg-green {
  background-color: #5AB52F;
}

.bkg-red {
  background-color: #CD0505;
}

.MuiAccordionSummary-content {
  margin: 0px!important;
}

div#panel-header-1,
div#panel-header-2,
div#panel-header-3,
div#panel-header-4 {
  min-height: 56px;
}

.ver-notas-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #039ECC;
}

.text-transform-none {
  text-transform: none!important;
}

.min-h {
  min-height: 760px;
}

.perfil {
  background: #E3E3E3;
}

.profile-data-container {
  margin-bottom: 1.5rem;
}

.profile-data-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #8F8F8F;
}

.profile-data-subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.profile-data-main-container {
  padding: 16px
}

.foto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 20px
}

.texto-dato {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.texto-valor {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.padding-datos {
  padding: 10px;
}

.texto-division {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #8F8F8F;
}

.items {
  border: 1px solid #E3E3E3;
  position: relative;
}

.titulo-validacion {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  display: flex;
}

.p-pendiente {
  padding: 16px 16px 0px 16px;
}

.history-icon {
  color: #8F8F8F;
}

.p-estatus {
  padding: 0px 16px 14px 16px;
}

.estatus {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  background: #8F8F8F;
  text-align: center;
}

.bb-validacion {
  border-bottom: 1px solid #C7C7C7;
}

.green-icon {
  color: #5AB52F;
}

.yellow-icon {
  color: #FFBC0E;
}

.red-icon {
  color: #CD0505;
}

.gray-icon {
  color: #8F8F8F;
}

.detail {
  border: 1px solid #E3E3E3;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-content-row {
  display: flex;
  width: 50%!important;
  /*height: 50%!important;*/
  margin: auto!important;
}

.flex-direction-column {
  flex-direction: column;
}

.pdf-view {
  position: relative;
  display: flex;
  width: 100%;
  height: 600px;
}

.pdf-container {
  max-height: 750px;
  overflow: scroll;
}

.group-buttons {
  position: absolute;
  right: 16px;
  z-index: 1;
}

.group-buttons .border {
  border: 1px solid #C7C7C7;
}

.group-buttons .bt-0 {
  border-top: 0px;
}

/*TODO: seguir acomodando los iconos de zoom y descarga y tambien poner el paginador y que no se desborde el espacio con respecto a la barra azul*/

.padding-box-datos {
  padding: 25px 37px 20px 37px;
}

.bkg-gray {
  background: #F4F4F4;
}

.pt-0 {
  padding-top: 0px!important;
}

.bkg-warning {
  background: #FFBC0E;
}

.bkg-alert {
  background: #CD0505;
}

.white-font {
  color: #FFFFFF;
}

.blue-icon {
  color: #039ECC;
}

.texto-add {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #039ECC;
}

.no-hover:hover {
  background-color: none!important;
}

.sin-notas-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8F8F8F;
}

.cerrar-notas-text {
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
  color: #8F8F8F;
}

.close-stack {
  padding-top: 10px;
  padding-right: 10px;
  text-align: center;
}

.padding-box-agregar {
  padding: 0px 37px 20px 37px;
}

.notas-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: #F4F4F4;
}

.header-notas {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #212121;
  margin: 0px 10px;
  padding: 10px 0px;
}

.w-text {
  color: #FFFFFF;
}

.notas-textfield {
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(33, 33, 33, 0.15);
  padding: 8px 12px;
}

.p-botones-form {
  padding: 0px 37px 0px 37px;
}

.boton-notas-form {
  width: 151px;
  height: 40px;
}

.ml-24 {
  margin-left: 24px!important;
}

.h-36 {
  height: 36px;
}

.solicitar-act {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.titulo-modal {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #212121;
}

.disc-style {
  display: list-item!important;
  list-style: disc;
}

.center-list {
  display: flex;
  justify-content: center;
}

.boton-solicitar {
  width: 100%;
  height: 48px;
}

.solicitar-autorizacion {
  height: 36px;
  width: 287px;
}

.center-botton {
  display: flex!important;
  margin-left: auto!important;
  margin-right: auto!important;
  width: auto!important;
}

.mY-17 {
  margin-top: 17px;
  margin-bottom: 17px!important;
}

.p-absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.bkg-status {
  background: #F4F4F4;
  padding: 10px 16px;
}

.rechazo-select {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.0)!important;
}

.no-padding-top {
  padding-top: 0px!important;
}

button.disabled-icon {
  background-color: rgba(0, 0, 0, 0.0) !important;
}

.bottom-gpo-buttons {
  position: absolute;
  bottom: 10px;
  margin-left: 45%;
  margin-right: 50%;
  display: flex;
}

.fix-crm {
  border-color: white;
}

.hw-100 {
  width: 100%;
  height: 100%;
}

.overflow-scroll {
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer;
}

.profile-photo-img {
  object-fit: cover;
  height: 120px;
  width: 120px;
  border-radius: 50%;
}