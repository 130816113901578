.container-wrapper {
  background-color: #f4f4f4;
  display: flex;
}

.frame-contratacion {
  border-radius: 12px;
  height: 100%;
  width: 100%;
  box-shadow: 4px 6px 16px #c7c7c7;
  background-color: #ffffff;
}

.frame-contratacion-detalle {
  padding: 25px 55px;
}

@media (max-width: 768px) {
  .frame-contratacion-detalle {
    padding: 1rem 1.5rem;
  }
}

.text-preguntas {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

/*style firma*/
.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}

.app {
  font-family: sans-serif;
  text-align: center;
}

.styles-canvas {
  border: 0.0625rem solid #212121;
  border-radius: 0.25rem;
  display: flex;
  width: 620px;
  height: 251px;
}

.href-terminos {
  padding-left: 0.5rem;
  color: #039ecc;
}

.text-contratacio {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */

  letter-spacing: 0.5px;

  /* Black */

  color: #212121;

  margin-left: 17px;
}

.text-contratacion-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-left: 17px;
}

.linea-amarilla {
  position: relative;
}

.text-ya {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  margin-left: 0px!important;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.text-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* Black */

  color: #212121;
}

.pl-vineta {
  padding-left: 50px !important;
}

.text-puntos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.div-text {
  /* Azul Inter */
  height: 46px;
  background: #F2FAFC;
  border-radius: 6px;
}

.text-div {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-left: 40px;

  /* Black 75% */

  color: #595959;
}
.text-correo {
  color: #039ecc;
}

.text-terminamos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.text-maximo {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* Black */

  color: #212121;
}

.imagen-documentos {
  max-width: 440px;
  max-height: 477px;
  padding: 10px;
}

.imagen-documentos > img {
  max-height: 457px;
  max-width: 420px;
}

.btn-text-mas-tarde {
  font-style: normal;
  font-weight: 600!important;
  font-size: 16px!important;
  line-height: 20px!important;
}

@media (max-width: 425px) {
  .btn-text-mas-tarde {
    font-style: normal;
    font-weight: 600!important;
    font-size: 12px!important;
    line-height: 15px!important;
  }
  .boton-cuestionario-sm {
    height: 44px;
    width: 100%;
  }
}