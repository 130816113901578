.w-bgWhite {
  width: 750px;
}

.zIndex-15 {
  z-index: 20 !important;
}

.title-beneficios {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0 0 0 4px;
}

.logo-insurer-mobile {
  width: 103px;
}

.card-selected-insurer {
  background-color: #ffff;
  border-radius: 12px;
  margin: 1.5rem 0.5rem;
  padding: 1rem;
}

@media (max-width: 360px) {
  .logo-insurer-mobile {
    width: 75px;
  } 
}

@media (min-width: 425px) {
  @media (max-width: 890px){
    .cabecera-beneficios {
      margin-bottom: 8px;
    }
    .valor-beneficios {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */
      text-align: right;
      color: #000000;
    }
    .valor-beneficios-aux {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
    }
    .w-95-mobile {
      width: 95% !important;
    }
    .pl-0-mobile {
      padding-left: 0% !important;
    }
    .pl-0-mobile {
      padding: 0% !important;
    }
    .ml-1 {
      margin-left: 1% !important;
    }
    .personaliza-tu-plan-titulo {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    .p-personaliza-tu-plan-descripcion {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
    .padding-1 {
      padding: 1%;
    }
    .img-goBack-mobile {
      width: 2%;
      margin: 0% 3%;
    }
    .vl-amarilla-titulo {
      top: -23px;
    }
    .cont-tarjetas-position {
      position: relative !important;
      margin-left: 6% !important;
      top: 0 !important;
      right: 0 !important;
    }
    .margin-title-beneficios-extras {
      margin: 1% 5% !important;
    }
    .w-bgWhite {
      width: 90% !important;
    }
    .hideOnMobile {
      display: none !important;
    }
    .cont-beneficios {
      padding: 1rem;
    }
    .p-beneficios {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px !important;
      letter-spacing: 0px;
      text-align: justify;
      padding-right: 3% !important;
    }

    .bg-beneficios-coaseguro {
      display: flex;
      text-align: center;
      justify-content: center;
      padding: 2%;
    }
    .txt-titulo-coberturas-extras-mobile {
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 15px !important;
      letter-spacing: 0em;
      text-align: left;
    }
    .txt-contenido-coberturas-extras-mobile {
      color: #595959;
    }
    .bg-azul5 {
      background-color: #f2fafc !important;
    }
  }
}
@media (min-width: 890px) {
  @media (max-width: 996px){
    .p-beneficios{
      font-size: 9px !important;
    }
  }}
@media (min-width: 996px) {
  @media (max-width: 1115px){
    .p-beneficios{
      font-size: 10px !important;
    }
  }}

@media (max-width: 450px) {
  .cabecera-beneficios {
    margin-bottom: 8px;
  }
  .valor-beneficios {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    text-align: right;
    color: #000000;
  }
  .valor-beneficios-aux {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
  }
  .w-95-mobile {
    width: 95% !important;
  }
  .pl-0-mobile {
    padding-left: 0% !important;
  }
  .pl-0-mobile {
    padding: 0% !important;
  }
  .ml-1 {
    margin-left: 1% !important;
  }
  .personaliza-tu-plan-titulo {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .p-personaliza-tu-plan-descripcion {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .padding-1 {
    padding: 1%;
  }

  .img-goBack-mobile {
    height: 12px;
    margin-right: 1rem;
  }

  .vl-amarilla-titulo {
    top: -23px;
  }
  .cont-tarjetas-position {
    position: relative !important;
    margin-left: 6% !important;
    top: 0 !important;
    right: 0 !important;
  }
  .margin-title-beneficios-extras {
    margin: 1% 5% !important;
  }
  .w-bgWhite {
    width: 90% !important;
  }
  .hideOnMobile {
    display: none !important;
  }
  .cont-beneficios {
    padding: 1rem;
  }

  .p-beneficios {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px !important;
    letter-spacing: 0px;
    text-align: justify;
    padding-right: 3% !important;
  }
  .bg-beneficios-coaseguro {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 2%;
  }
  .data-flag-feature {
    margin: 0;
  }
  
  .title-beneficios {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .txt-titulo-coberturas-extras-mobile {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 15px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  .txt-contenido-coberturas-extras-mobile {
    color: #595959;
  }
  .bg-azul5 {
    background-color: #f2fafc !important;
  }
}

@media (min-width: 800px) {
  .showOnlyOnMobile {
    display: none !important;
  }
  .pl-10 {
    padding-left: 10% !important;
  }
}

.half-circle-left {
  width: 20px;
  background-color: #f4f4f4;
  border-bottom-right-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid #f4f4f4;
  border-right: 0px;
  content: "";
}

.half-circle-right {
  width: 20px;
  background-color: #f4f4f4;
  border-bottom-left-radius: 110px;
  border-top-left-radius: 110px;
  border: 10px solid #f4f4f4;
  border-right: 0px;
  content: "";
}

.list-estilos-plant-personalizado {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8f8f8f;
}

.titulo-cabecera-aviso-importante {
  color: #8f8f8f;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.txt-aviso-importante {
  color: #595959;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.input-no-colaborador {
  background-color: #ffffff;
}

.encabezado-aviso-colaborador {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #595959;
}

.sub-cabecera-aviso-colaborador {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.txt-cabecera-aviso-colaborador {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #304659;
}

.separador-detalles-cotizacion {
  border: none;
  border-style: dashed #f4f4f4;
  border-top: 1px;
  background-color: #f4f4f4;
  height: 1px;
  width: 80%;
}

.text-auxiliar-cupones {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #039ecc;
  margin-bottom: 15px;
}

.cajon-superior-detalle {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.text-cupones-detalle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.vl-titulo-personalizar {
  border: 4.15px solid #ffd60e;
  border-bottom: none;
  border-top: none;
  border-left: none;
  height: 55px;
  margin-top: 2% !important;
  margin-right: 1%;
}

.vl-amarilla-titulo {
  border-bottom: none;
  border-top: none;
  border-left: none;
  height: 38px;
  margin-top: 5% !important;
  width: 0;
  margin: 0;
  position: absolute;
}

.ml-2 {
  margin-left: 2%;
}

.ml-1 {
  margin-left: 1% !important;
}

.ml-3 {
  margin-left: 3%;
}

.w-1 {
  width: 1%;
}

.pb-2 {
  padding-bottom: 2% !important;
}

.pt-2 {
  padding-top: 2%;
}

.m-0 {
  margin: 0;
}

.mtb-1 {
  margin: 1% 0%;
}

.ml-5 {
  margin-left: 5%;
}

.ml-11 {
  margin-left: 11%;
}

.mr-3 {
  margin-right: 3%;
}

.mr-7 {
  margin-right: 7%;
}

.m-10 {
  margin: 10%;
}

.p-5 {
  padding: 5%;
}

.mt-40 {
  margin-top: 40% !important;
}

.fondoStyle {
  min-height: 100vh;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #f4f4f4;
}

.bg-white {
  background-color: #ffff;
  border-radius: 12px;
  margin: 1.5rem 0.5rem;
  padding: 0.5rem;
}

.bg-beneficios {
  background-color: rgba(12, 55, 113, 0.05);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

div.bg-beneficios::after, div.bg-beneficios::before {
  content: ' ';
  background-color: #FAFBFC;
  width: 16px;
  height: 16px;
  display: inline-block;
}

div.bg-beneficios::after {
  margin-left: auto;
  transform: translate(8px, 0) rotate(45deg);
}

div.bg-beneficios::before {
  margin-right: auto;
  transform: translate(-8px, 0) rotate(45deg);
}

.p-beneficios {

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.63px;
  letter-spacing: 0px;
  text-align: left;
  padding-right: 12%;
}

.sub-txt-beneficios {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: center;
}

.cont-beneficios {
  border-radius: 6px;
  background-color: rgba(12, 55, 113, 0.02);
  margin-top: 2%;
}

.cont-beneficios-extras {
  height: 402px;
}

.coinsurance-cap-form-control {
  width: 230px;
}

@media (max-width: 360px) {
  .coinsurance-cap-form-control {
    width: 100%;
  }
}

.bg-grey {
  background-color: rgba(12, 55, 113, 0.02);
}

.banderin-beneficios {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFD60E;
  font-size: 16px;
  font-weight: 700;
  margin: 8px 0;
  height: 29px;
  border-radius: 0 14.5px 14.5px 0;
  width: 332px;
  transform: translate(-35px, 0);
}

.banderin-beneficios::before {
  content: ' ';
  background-color: #f4f4f4;
  display: inline-block;
  height: 20.5px;
  width: 20.5px;
  transform: translate(-31.5px, 0) rotate(45deg);
}

@media (max-width: 360px) {
  .banderin-beneficios {
    width: 100%;
    transform: translate(0,0);
  }

  .banderin-beneficios::before {
    background-color: white;
    transform: translate(-21.5px, 0) rotate(45deg);
  }
}

.bg-beneficios-adic {
  background-color: #e8f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.bg-beneficios-adic::after, div.bg-beneficios-adic::before {
  content: ' ';
  background-color: #FAFBFC;
  width: 16px;
  height: 16px;
  display: inline-block;
}

div.bg-beneficios-adic::after {
  margin-left: auto;
  transform: translate(8px, 0) rotate(45deg);
}

div.bg-beneficios-adic::before {
  margin-right: auto;
  transform: translate(-8px, 0) rotate(45deg);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-tarjeta-cotizacion {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 3%;
}

.subTxt-tarjeta-cotizacion {
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.txt-plan-esencial {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.txt-monto-plan {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
}

.cont-tarjetas-position {
  position: absolute;
}

.pt-15rem {
  padding-top: 1.5rem;
}

.regresar-opciones {
  margin-left: 17px;
  text-decoration: none;
  color: #039ecc;
}

.bnt-personalizar {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #039ecc !important;
  margin-bottom: 4% !important;
  margin-top: 2%;
}

.btn-contratar {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  background-color: #039ecc !important;
  color: #fff !important;
}

.txt-contenido-coberturas-extras {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.txt-titulo-coberturas-extras {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.divider-personalizar {
  margin: 5% 0% !important;
  border-style: dashed !important;
}

.margin-personaliza-plan-title {
  margin: 2% 0% 0% 1%;
}

.p-etiquetavalor {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #212121;
}

.hr {
  width: 68%;
  height: 0;
  background: #f4f4f4;
  opacity: 0.4;
  border: 1px dashed #595959 !important;
}

.hr-dos {
  width: 100%;
  height: 0;
  background: #f4f4f4;
  opacity: 0.4;
  border: 1px dashed #595959 !important;
}

.hr-hidden {
  clear: both;
  visibility: hidden;
}

.boton-flotante {
  height: 56px;
  border-radius: 8px;
  background-color: #039ecc !important;
  color: #fff !important;
}
