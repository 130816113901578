@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700;800;900&display=swap);

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.label-input {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.grid-item-input {
  width: 100%;
  border: 1px solid #C7C7C7;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  height: 48px;
}

.grid-item-select {
  width: 230px;
  background-color: #fff;
  height: 48px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mX-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mX-30 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mX-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mX-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mX-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mX-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mX-1rem {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 8px;
}

.pt-0px-modal {
  padding-top: 0px !important;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pb-8px {
  padding-bottom: 8px;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pX-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.pY-1rem {
  padding-right: 1rem;
  padding-left: 1rem;
}

hr {
  border-bottom: none;
  border-top: 1px solid #C7C7C7;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.text-color-white {
  color: white !important;
}

.text-color-black {
  color: black !important;
}

.text-align-center {
  text-align: center;
}

.circulo-inicio-gde {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: -430px;
  top: 900px;
  overflow: hidden;
}

.circulo-inicio {
  width: 580px;
  height: 580px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: 120px;
  top: 130px;
  overflow: hidden;
}

.circulo-gde {
  width: 506px;
  height: 506px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: -410px;
  top: 132px;
  overflow: hidden;
}

.circulo {
  width: 368px;
  height: 368px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: 69px;
  top: 68px;
  overflow: hidden;
}

@media (max-width: 1024px) {

  .circulo-gde,
  .circulo,
  .circulo-inicio,
  .circulo-inicio-gde {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .vertical-line {
    display: none;
  }
}

@media (min-width: 425px) {
  .MuiGrid-root {
    max-height: 100% !important;
  }
}

@media (min-width: 424px) {
  .MuiContainer-root {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.box-bienvenida {
  height: 100%;
  max-height: 590px;
  display: flex;
  align-items: center;
}

.box-perfilamiento {
  height: 100%;
  max-height: 590px;
}

.box-perfilamiento-form {
  height: 100%;
  max-height: 590px;
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .MuiGrid-root {
    max-height: 100% !important;
  }

  .MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  .normalize-modal {
    padding-bottom: 10px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .pt-15px-modal {
    padding-top: 15px !important;
  }

  .pb-10px-modal {
    padding-bottom: 10px !important;
  }

  .boton-form {
    height: 46px !important;
    width: 140px !important;
  }

  .box-bienvenida {
    height: 100%;
    max-height: 590px;
  }

  .box-perfilamiento {
    min-height: 100%;
    height: 100%;
    max-height: 590px;
  }

  .box-perfilamiento-form {
    height: 100%;
    max-height: 570px;
  }

  .py-10-m {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 390px) {
  .boton-form-datos {
    height: 48px !important;
    width: 150px !important;
  }
}

@media (min-width: 425px) and (max-width: 1023px) {
  .boton-form-datos {
    height: 46px !important;
    width: 90% !important;
  }
}

@media (max-width: 320px) {
  .MuiButton-root {
    padding: 5px !important;
  }
}

.MuiFormHelperText-root.Mui-error {
  color: #CD0505;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

select {
  width: 100%;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  width: 190px;
}

.button-continuar {
  height: 48px;
  font-style: normal;
  font-size: 16px !important;
  text-align: center;
}

.nombre-asegurado {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Black */

  color: #212121;
}

.advertencia-exclusion {
  display: flex;
  align-content: center;
  color: #CD0505;
}

.completar-datos {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* Azul Inter */

  color: #039ECC;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.space-b {
  justify-content: space-between;
}

.boton-accion-modal {
  width: 100%;
  max-width: 100%;
  height: 48px;
}

.normalize-modal {
  padding-top: 0;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 0px !important;
  width: 100% !important;
}

.asegurado-modal {
  margin: 0px !important;
  width: 100% !important;
}

.minus-pl-32px {
  padding-left: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.boton-submit {
  height: 48px;
  width: 100%;
}

.boton-form {
  height: 48px;
  width: 100%;
  max-width: 100%;
}

/*
* para escritorioy 425 esta bien,
* para 768 y más pequenio de 425 debe tener regla
*/
.boton-form-datos {
  height: 48px;
  width: 168px;
  max-width: 100%;
}

.div-boton-derecho {
  display: flex;
  justify-content: flex-end;
}

.boton-form-seleccionado {
  background-color: #039ECC !important;
  color: white !important;
}

.boton-para {
  height: 48px;
  max-width: 100%;
  background: #FFFFFF;
  opacity: 0.8;
  /* Black 75% */

  border: 1px solid #595959;
  box-sizing: border-box;
  border-radius: 10px;
}

.border-x {
  border-top: 1px solid rgba(89, 89, 89, .4);
  border-bottom: 1px solid rgba(89, 89, 89, .4);
}

.item-tarjeta {
  color: #212121 !important;
}

.label-enfermedades {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */


  color: #000000;
}

.display-hidden {
  display: hidden !important;
}

textarea {
  width: 100%;
}


.back-button {
  color: #039ECC !important;
}

.hola-txt {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  color: #000000;
}

.desc-txt {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.datos-txt {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.fecha-naciemiento {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */


  color: #000000;
}

.vertical-line {
  position: fixed;
  border: none;
  border-left: 1px solid #595959;
  height: 50%;
  width: 1px;
  margin: 15px 0 10px 0;
  opacity: 0.4;
}

.header-common a,
.header-common .item-menu {
  color: black;
  font-size: 16px;
}

.header-gradient a,
.header-gradient .item-menu {
  color: white;
  font-size: 16px;
}

.case-none {
  text-transform: none !important;
}

.d-flex {
  display: flex;
}

.vertical-divider {
  opacity: 0.4 !important;
  border: 1px solid #595959;
  border-color: #595959 !important;
}

.divider {
  opacity: 0.4;
  /* Black 75% */
  border-top: 1px solid #212121;
  border-color: #212121 !important;
}

.d-flex-center {
  display: flex;
  align-content: center;
  align-items: center;
}

.hidden-movil {
  display: none !important;
}

.p-genero-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.bg-genero-tooltip {
  background-color: #EBF8FB;
}

.bg-dialog-datos {
  background-color: #F2FAFC;
}

.texto-dialog-datos {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.texto-dialog-aviso-datos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #595959;
}

.a-dialog-aviso-datos {
  color: #039ECC;
}

.boton-dialog-aviso-datos {
  background-color: #FFFFFF !important;
  border: 1px solid #039ECC;
  color: #039ECC;
}

.texto-pedimos-datos {
  display: flex;
  text-decoration: underline;
  font-size: 14px;
  color: #039ECC;
}

.texto-pedimos-datos-cursor {
  cursor: pointer;
  margin-top: 32px;
}

.text-transform-none {
  text-transform: none;
}

.background-w {
  background-color: white !important;
}

.d-none {
  display: none !important;
}

.accordion-w100 {
  width: 100%;
}

.column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.text-bold {
  font-weight: 700;
}

img[src="“https://ad.doubleclick.net/ddm/activity/src=10089018;type=invmedia;cat=mx_in000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?”"]{
  display: none;
}

.header-common {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #000000;
  height: 64px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-around;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 3px 0 3px 0;
}

.logo-commons {
  background-color: #0b9ecb;
}

.button-login {
  width: 132px;
  height: 40px;
}

.logo-inter {
  width: 137.68px;
  height: 32.02px;
}

/*footer*/
.footer {
  background: #212121;
  width: 100%;
}

/*.footer-fijo {
  position: relative;
  bottom: 0;
  left: 0;
}*/

.header-footer {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff;
  line-height: 19.5px !important;
}

.body-footer {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #fff;
  line-height: 19.5px !important;
}

.a {
  text-decoration: none;
}

.logos {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.logos-footer {
  display: inline-flex;
}
.footer-footer {
  font-weight: 300;
  font-size: 10px;
  color: #fff;
}

.container-wrapper {
  background-color: #f4f4f4;
  display: flex;
}

.texto-tarjeta {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #000000;
}

.agregar {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* Azul Inter */

  color: #039ecc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tarjeta {
  /* White */
  background: #ffffff;
  /* Shadow-Card */
  box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.05);
  border-radius: 8px;
}

.imagen-flujo {
  max-height: 570px;
  padding: 20px;
}

.imagen-flujo > img {
  max-height: 530px;
  max-width: 440px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/*itemMenu*/

.promo{
  padding-left: 1.5rem;
}

@media (min-width: 769px) {
  .promo{
    padding-left: 14px;
  }
  .button-login { 
    width: 190px; 
    padding: 6px 14px!important;
    font-size: 15px!important;
  }
  .button-login > svg {
    font-size: 18px;
  }
  .flex-xl {
    justify-content: space-between!important;
  }
}


.MuiMenuItem-root:hover {
  background-color: #039ECC
  !important;
}

.textMovil{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.list-inicio{
  background: #F4F4F4;;
}

.button-text-ayuda{
 
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #212121;
}

@media (max-width: 767px) and (min-width: 360px) {
  .imagen-flujo {
    max-height: 220px;
    padding: 20px;
  }
  
  .imagen-flujo > img {
    max-height: 220px;
    max-width: 195px;
  }

  .logo-inter {
    width: 113.39px;
    height: 26.37px;
  }
}

/*Cabecera Inicio*/
.logo-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* White */
  color: #ffffff;
}

div.coberturas-adicionales__container {
  border-radius: 8px;
  border: 2px solid #77CAE3;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.3);
}

div.coberturas-adicionales__container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: clamp(90px, 14vh, 160px);
  background-color: #C0E6F2;
  color: #039ECC;
  font-size: clamp(14px, 0.8rem, 18px);
  text-align: center;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 40px;
}

div.coberturas-adicionales__container > div:nth-child(even) {
  background-color: #039ECC;
  color: white;
}

h2.coberturas-adicionales__title {
  font-size: clamp(20px, 5vw, 28px);
  font-weight: 700;
  text-align: center;
  width: 80%;
}

img.coberturas__img {
  object-fit: cover;
  width: clamp(313px, 100%, 560px);
}

h2.coberturas-title {
  font-weight: 700;
  font-size: clamp(20px, 5vw, 28px);
}

ul.coberturas-adicionales-list > li {
  font-weight: 400;
  font-size: clamp(18px, 4vw, 20px);
  margin-bottom: 24px;
}

ul.coberturas-adicionales-list > li::marker {
  font-size: 10px;
  
}

.main-title {
  font-size: 50px;
  line-height: 39px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  margin: 5% 0%;
}

.text-h4-white {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
  color: #ffffff;
}

.container-form-size {
  height: 535px;
  width: 461px;
}

.centrado-tabs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnFrameNCInicioHabilitado {
  background: #FFFFFF;
  cursor: pointer;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  width: 50%;
  padding: 3%;
  box-shadow: rgb(99 99 99 / 10%) 0px -4px 4px 0px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.btnFrameInicioNCDeshabilitado {
  background: #F4F4F4;
  cursor: pointer;
  width: 38%;
  border-top-left-radius: 8px;
  padding: 3%;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.btnFrameYTUCInicioDeshabilitado {
  background: #F4F4F4;
  cursor: pointer;
  width: 63%;
  border-top-right-radius: 8px;
  padding: 3%;
  color: #8F8F8F;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.btnFrameYTUCInicioHabilitado {
  background: #FFFFFF;
  cursor: pointer;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  width: 50%;
  padding: 3%;
  box-shadow: rgb(99 99 99 / 10%) 0px -4px 4px 0px;

  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.padding-formulario-inicio {
  padding-left: 10%;
}

.espacio-divisor-secciones {
  display: none;
  z-index: 0;
  background-color: #f4f4f4;
}

/*Tarjetas Informativas Inicio*/
.main-title-cards-info {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #212121;
}

.title-cards-blue {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 5%;

  color: #039ecc;
}

.titulo-box-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #039ecc;
  margin-bottom: 12px;
}
.desc-box-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}
.titulo-plans {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
}

.desc-plans {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}


/*Mobile*/
/*XSsmall*/
@media (min-width: 0px) and (max-width: 359px) {
  .espacio-divisor-secciones {
    display: flex;
    height: 550px;
  }
}

/*Small*/
@media (min-width: 360px) and (max-width: 389px) {

  .espacio-divisor-secciones {
    display: flex;
    height: 170px;
  }
}

/*Medium*/
@media (min-width: 390px) and (max-width: 424px) {
  .espacio-divisor-secciones {
    display: flex;
    height: 250px;
  }
}

/*Tablet*/
@media (min-width: 425px) and (max-width: 767px) {

  .espacio-divisor-secciones {
    display: flex;
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .alt {
    height: 220px;
  }
  .pad-pq-inter {
    padding: 20px 0px 20px 0px;
  }
  .pad-pq-inter-relleno {
    padding: 60px 0px 60px 0px;
  }
}

/*Mobile y Tablet*/
@media (min-width: 0px) and (max-width: 1023px) {
  .logo-text {
    font-size: 20px;
  }
  .main-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48.76px;
    letter-spacing: 0.5px;
  }
  .text-h4-white {
    font-size: 29px;
    line-height: 34.13px;
    font-weight: 700;
    margin: 0;
  }
}

.form-container {
  /* White */
  background: #ffffff;
  /* Elevation 01dp */
  box-shadow: 2px 4px 4px rgba(33, 33, 33, 0.04);
  border-radius: 8px;

  padding: 0px 1px 24px;
  width: 350px;
}


@media (min-width: 426px) and (max-width: 1024px) {
  .padding-formulario-inicio {
    padding: 1rem;
    margin-top: 59px;
  }
}
@media (min-width: 425px) and (max-width: 1023px) {
  .btnFrameNCInicioHabilitado,
  .btnFrameInicioNCDeshabilitado,
  .btnFrameYTUCInicioDeshabilitado,
  .btnFrameYTUCInicioHabilitado {
    padding: 2%;
    font-size: 14px;
  }
  .text-h4-white {
    font-size: 28px;
  }
}

@media (min-width: 0px) and (max-width: 425px) {
  .btnFrameNCInicioHabilitado,
  .btnFrameInicioNCDeshabilitado,
  .btnFrameYTUCInicioDeshabilitado,
  .btnFrameYTUCInicioHabilitado {
    padding: 2%;
    font-size: 14px;
  }

  .padding-formulario-inicio {
    padding: 1rem;
    margin-top: 59px;
  }
}

@media (max-width: 450px) {
  .form-inicio-mobile-position {
    position: absolute;
    z-index: 2;
    left: 10px;

  }

  .with-box-form {
    width: 369px;
  }
}

@media (max-width: 380px) {
  .form-container {
    width: 300px;
  }
}

@media (max-width: 360px) {
  .form-container {
    padding: 12px;
  }
}

.mb-15 {
  margin-bottom: 1.5rem;
}

.form-titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

/* input[type="text"] {
  width:10em;
} */


.logo-seguro {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  letter-spacing: 1px;
  color: #ffffff;
}

.texto-logo {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.texto-logo-seguro {
  font-size: 60px;
  line-height: 39px;
  color: #ffffff;
  font-style: normal;
  font-weight: bold;
  margin: 5% 0%;
}

@media (max-width: 450px) {
  .texto-logo-seguro {
    font-size: 40px;
  }
}

@media (max-width: 450px) {
  .texto-logo {
    font-size: 28px;
  }
}

.cinturon {
  height: 80px;
  background: #ffffff;
}

.cinturon-text {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* Black */

  color: #212121;
}

.landing-body {
  background: white;
}

.titulo-seccion {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: #212121;
}

.title-plan-section {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #212121;
  text-transform: uppercase;
}

.plan-para-ti-text {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #212121;
}

@media (max-width: 450px) {
  .titulo-seccion {
    font-size: 28px;
    font-weight: 700;
    line-height: 34.13px;
    text-align: center;
  }
}

.subtitulo-seccion {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
}

.box {
  padding: 16px 0;
  border-radius: 8px;
  min-height: 180px;
  max-height: 100%;
  max-width: 100%;
  background: #ffffff;
}

.accordion-item {
  min-height: 180px;
  max-height: 100%;
  max-width: 100%;
  background: #ffffff;
}

.box-pq-inter {
  padding: 0px 10px;
}

@media (max-width: 425px) {
  .accordion-item {
    min-height: 0px;
    padding: 16px 0px;
  }

  .box-pq-inter{
    padding: 0px 10px;
  }
}


.tiendas {
  display: flex;
  justify-content: space-around;
}

.tiendas-movil {
  display: flex;
  justify-content: center;
  padding-top: 16px;
}

.plans {
  padding: 15px;
  width: auto;
}

.algo-mas {
  border: 1px solid #77cae3;
  box-sizing: border-box;
  border-radius: 16px;
  min-width: 150px;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.slider-gmm {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.algo-mas-movil {
  border: 1px solid #77cae3;
  box-sizing: border-box;
  border-radius: 16px;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: 8px;
  margin-right: 8px;
}

.slider-animation {
  display: flex;
  width: 200px;
  position: relative;
  -webkit-animation: sliderGmm 20s infinite;
          animation: sliderGmm 20s infinite;
}

#sliderGMM {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes sliderGmm {
  from {
    left: 100%;
  }

  to {
    left: -400%;
  }
}

@keyframes sliderGmm {
  from {
    left: 100%;
  }

  to {
    left: -400%;
  }
}

.algo-mas img {
  width: 115px;
}

/*Hospitales*/
.boxh {
  border-radius: 8px;
  height: 406px;
  max-width: 100%;

  background: #039ecc;
}

.texh {
  font-style: normal;
  line-height: 39px;
  font-weight: bold;
  font-size: 32px;
  text-align: center;

  color: #fff;
}

.text-h-dos {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;

  color: #fff;
}

.text-h-tres {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
}

.plansSelec {
  background: #ffffff;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
}

.boxPlan {
  position: absolute;
  width: 485px;
  height: 46px;
  left: 770px;
  top: 2163px;
}

/*FloatingButtons*/
.textfab {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
}

.textfab2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #039ecc;
}

/*DudasInicioModal*/

.textDudas {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #424242;
}

.espacio {
  padding-top: 1.5rem;
}

.href-modal {
  padding-left: 0.5rem;
  color: #039ecc;
  cursor: pointer;
}

.button {
  background: #039ecc;
}

/*PreguntasFrecuentes*/

.textPF {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  padding-top: 5rem;

  color: #212121;
}

.linea {
  border-top: 1px solid black;
  height: 2px;
  max-width: 200px;
  padding: 0;
  margin: 20px auto 0 auto;
}

.texto-numero-cotizacion {
  font-style: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 24px !important;
  /* identical to box height, or 171% */

  display: flex !important;
  align-items: center !important;
  text-align: right;
  text-transform: none !important;
}

.pt-minus-0px {
  padding-top: 0px !important;
}

/*modal ayuda*/
.modal-titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-text-dos {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  /* Black */

  color: #212121;
}

.modal-tel {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.25px;

  /* Black */

  color: #212121;
}

.modal-bg {
  background: #f5f5f5;
  width: 100%;
}

.modal-individual-padding {
  padding: 20px 24px;
}

@media (min-width: 426px) {
  .hide-desktop {
    display: none !important;
  }
}

@media (min-width: 821px) {
  .hide-xl {
    display: none !important;
  }
}

@media (max-width: 769px) {
  .logo-seguro {
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    letter-spacing: 0.5px;
  }

  .form-container {
    padding: 24px;
    width: 320px;
  }
}
@media (max-width: 820px) {
  .hide-movil {
    display: none !important;
  }
}

@media (max-width: 426px) {
  .logo-seguro {
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    letter-spacing: 0.5px;
  }

  .texto-seguro {
    font-size: 28px;
    line-height: 34px;
  }

  .cinturon {
    display: none !important;
  }

  .modal-individual-padding {
    padding: 15px 18px;
  }
}

.comunica {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-transform: lowercase;
  /* identical to box height, or 100% */

  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  /* Azul Inter */

  color: #039ecc;
}

.w100px {
  width: 70px;
}

.h70 {
  height: 70px;
}

.pr-16px {
  padding-right: 16px;
}

.pt-8px {
  padding-top: 8px;
}

.pt-13px {
  padding-top: 13px;
}

.h-55px {
  height: 55px;
}

.p16-form-inicio {
  padding: 8px 8px 0px 16px !important;
}

.tienes-cotizacion {
  padding: 8px 16px 0px 16px !important;
}

.form-inicio-btns-top {
  width: 398px;
  height: 50px;
}

.pb-24px {
  padding-bottom: 24px;
}

.boton-high-inicio {
  height: 55px;
}

.mb-inputs-inicio {
  margin-bottom: 6%;
}

.padding-box-inter-inicio {
  padding: 20px 0px 0px 0px !important;
}

.img-banner-desktop {
  width: 100%;
  border-radius: 8px;
  margin-top: 2%;
}

@media (max-width: 450px) {
  .padding-main-titulo-inicio {
    padding: 4px 30px !important;
  }
}

@media (max-width: 450px) {
  .mb-35-mobile-banner {
    margin-top: 35% !important;
  }
}

@media (max-width: 450px) {
  .mt-120-mobile-inter {
    margin-top: 120% !important;
  }
}

.planes-mobile-accordeon {
  margin: 5% 0%;
  border-radius: 8px !important;
}

.mb-5-divider-mobile {
  margin-bottom: 5% !important;
}

.showOnlyOnMobile {
  display: none;
}

@media (max-width: 598px) {
  .showOnlyOnMobile {
    display: block !important;
  }
}

.hideOnlyOnMobile {
  display: block;
}

@media (max-width: 599px) {
  .hideOnlyOnMobile {
    display: none !important;
  }
}

@media (max-width: 450px) {
  .w-98-mobile {
    width: 98%;
  }
}

.pl-16-form {
  padding-left: 16px !important;
}

.pl-32-form {
  padding-left: 32px !important;
}

@media (min-width: 768px) and (max-width: 820px) {
  .pl-32-form {
    padding-left: 26px !important;
  }
}

.font-size-terminos-condiciones-form {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: left;
}

@media (min-width: 425px) {
  @media (max-width: 769px) {
    .margin-left-form-inicio {
      margin-left: -50px;
    }
  }
}

/* Seccion para el slide */

div.gallery-slider-container {
	margin-top: 48px;
	overflow-x: scroll;
	cursor: grab;
	scroll-behavior: smooth;
	width: clamp(380px, 95vw,1300px);
}

div.gallery-slider-container::-webkit-scrollbar {
	display: none;
}

.gallery-slider-container > .gallery-slider {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 10px 10px;
}

.gallery-slider > .card:first-child {
	height: 370px;
	width: clamp(240px, 32vw,330px);
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	margin: 0 24px 0 42px;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	-webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-slider > .card {
	height: 370px;
	width: clamp(240px, 32vw,330px);
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	margin: 0 24px 0 0;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	-webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-slider > .card > .card-content > h3 {
	text-align: center;
}

.gallery-slider > .card > .card-content > p {
	text-align: center;
	font-size: clamp(12px, 1.5vw, 14px);
}

.gallery-slider > .card > .card-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.gallery-slider > .card > .card-image > img {
	width: clamp(160px, 25vw, 214px);
}

.gallery-slider > .card:nth-child(even) {
	background-color: rgba(244, 244, 244, 1);
}

@media (max-width:899px) {
  .coberturas-title {
    text-align: center;
  }
}

@media (min-width:650px) and (max-width:750px) {
  .coberturas-adicionales-list {
    padding-left: 80px !important;
  }
}

@media (min-width:751px) and (max-width:899px) {
  .coberturas-adicionales-list {
    padding-left: 120px !important;
  }
}

@media (min-width:900px) {
  .coberturas-title {
    text-align: center;
  }
}
.modal-contenedor {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

:focus-visible {
  outline: none!important;
}

.modal-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* App.css */
/* Estilos para el contenido principal de tu página */
.App {
    /* ... Otros estilos si los tienes ... */
    position: relative;
  }
  
  /* Estilos personalizados para el modal */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    position: relative;
    border-radius: 8px;
    text-align: -webkit-right;
    width: 70%;
  }
  
  .modal-image {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-button-close {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background: white;
    width: 40px;
    height: 40px;
  }

 /*  .carousel-image {
    cursor: pointer;
  } */

  @media screen and (max-width: 500px) {
    .slick-prev::before {
      left: -35px !important;
    }

    .slick-next::before {
      right: -5px !important;
    }
  }
.pregunta-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  letter-spacing: 0.25px;

  /* Black */

  color: #212121;
}

.contenido-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Black */

  color: #212121;
}

.container-wrapper {
  background-color: #f4f4f4;
  display: flex;
}

.frame-contratacion {
  border-radius: 12px;
  height: 100%;
  width: 100%;
  box-shadow: 4px 6px 16px #c7c7c7;
  background-color: #ffffff;
}

.frame-contratacion-detalle {
  padding: 25px 55px;
}

@media (max-width: 768px) {
  .frame-contratacion-detalle {
    padding: 1rem 1.5rem;
  }
}

.text-preguntas {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

/*style firma*/
.container {
  width: 100%;
  height: 100vh;
  top: 10%;
  left: 10%;
}

.sigContainer {
  width: 80%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.signatureCanvas {
  border: 1px solid black;
  width: 100%;
  min-height: 500px;
}

.app {
  font-family: sans-serif;
  text-align: center;
}

.styles-canvas {
  border: 0.0625rem solid #212121;
  border-radius: 0.25rem;
  display: flex;
  width: 620px;
  height: 251px;
}

.href-terminos {
  padding-left: 0.5rem;
  color: #039ecc;
}

.text-contratacio {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */

  letter-spacing: 0.5px;

  /* Black */

  color: #212121;

  margin-left: 17px;
}

.text-contratacion-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-left: 17px;
}

.linea-amarilla {
  position: relative;
}

.text-ya {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  margin-left: 0px!important;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.text-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* Black */

  color: #212121;
}

.pl-vineta {
  padding-left: 50px !important;
}

.text-puntos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.div-text {
  /* Azul Inter */
  height: 46px;
  background: #F2FAFC;
  border-radius: 6px;
}

.text-div {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-left: 40px;

  /* Black 75% */

  color: #595959;
}
.text-correo {
  color: #039ecc;
}

.text-terminamos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Black */

  color: #212121;
}

.text-maximo {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  /* Black */

  color: #212121;
}

.imagen-documentos {
  max-width: 440px;
  max-height: 477px;
  padding: 10px;
}

.imagen-documentos > img {
  max-height: 457px;
  max-width: 420px;
}

.btn-text-mas-tarde {
  font-style: normal;
  font-weight: 600!important;
  font-size: 16px!important;
  line-height: 20px!important;
}

@media (max-width: 425px) {
  .btn-text-mas-tarde {
    font-style: normal;
    font-weight: 600!important;
    font-size: 12px!important;
    line-height: 15px!important;
  }
  .boton-cuestionario-sm {
    height: 44px;
    width: 100%;
  }
}
.blue-bar {
  height: 51px;
  background: #041431;
  width: 100%;
  color: white;
  padding: 12px 40px
}

.box-flex-center {
  display: flex;
  align-items: center;
}

.blue-bar-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
}

.blue-bar-subtext {
  font-style: normal;
  font-weight: 400!important;
  font-size: 16px!important;
  line-height: 20px!important;
  color: #FFFFFF!important;
}

.cmb-w {
  width: 250px;
  padding-left: 7px;
}

.bkg-cmb-gray {
  background-color: #8F8F8F;
}

.bkg-green {
  background-color: #5AB52F;
}

.bkg-red {
  background-color: #CD0505;
}

.MuiAccordionSummary-content {
  margin: 0px!important;
}

div#panel-header-1,
div#panel-header-2,
div#panel-header-3,
div#panel-header-4 {
  min-height: 56px;
}

.ver-notas-text {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #039ECC;
}

.text-transform-none {
  text-transform: none!important;
}

.min-h {
  min-height: 760px;
}

.perfil {
  background: #E3E3E3;
}

.profile-data-container {
  margin-bottom: 1.5rem;
}

.profile-data-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #8F8F8F;
}

.profile-data-subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.profile-data-main-container {
  padding: 16px
}

.foto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 20px
}

.texto-dato {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.texto-valor {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.padding-datos {
  padding: 10px;
}

.texto-division {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #8F8F8F;
}

.items {
  border: 1px solid #E3E3E3;
  position: relative;
}

.titulo-validacion {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  display: flex;
}

.p-pendiente {
  padding: 16px 16px 0px 16px;
}

.history-icon {
  color: #8F8F8F;
}

.p-estatus {
  padding: 0px 16px 14px 16px;
}

.estatus {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  background: #8F8F8F;
  text-align: center;
}

.bb-validacion {
  border-bottom: 1px solid #C7C7C7;
}

.green-icon {
  color: #5AB52F;
}

.yellow-icon {
  color: #FFBC0E;
}

.red-icon {
  color: #CD0505;
}

.gray-icon {
  color: #8F8F8F;
}

.detail {
  border: 1px solid #E3E3E3;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-content-row {
  display: flex;
  width: 50%!important;
  /*height: 50%!important;*/
  margin: auto!important;
}

.flex-direction-column {
  flex-direction: column;
}

.pdf-view {
  position: relative;
  display: flex;
  width: 100%;
  height: 600px;
}

.pdf-container {
  max-height: 750px;
  overflow: scroll;
}

.group-buttons {
  position: absolute;
  right: 16px;
  z-index: 1;
}

.group-buttons .border {
  border: 1px solid #C7C7C7;
}

.group-buttons .bt-0 {
  border-top: 0px;
}

/*TODO: seguir acomodando los iconos de zoom y descarga y tambien poner el paginador y que no se desborde el espacio con respecto a la barra azul*/

.padding-box-datos {
  padding: 25px 37px 20px 37px;
}

.bkg-gray {
  background: #F4F4F4;
}

.pt-0 {
  padding-top: 0px!important;
}

.bkg-warning {
  background: #FFBC0E;
}

.bkg-alert {
  background: #CD0505;
}

.white-font {
  color: #FFFFFF;
}

.blue-icon {
  color: #039ECC;
}

.texto-add {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #039ECC;
}

.no-hover:hover {
  background-color: none!important;
}

.sin-notas-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8F8F8F;
}

.cerrar-notas-text {
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 12px;
  color: #8F8F8F;
}

.close-stack {
  padding-top: 10px;
  padding-right: 10px;
  text-align: center;
}

.padding-box-agregar {
  padding: 0px 37px 20px 37px;
}

.notas-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: #F4F4F4;
}

.header-notas {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #212121;
  margin: 0px 10px;
  padding: 10px 0px;
}

.w-text {
  color: #FFFFFF;
}

.notas-textfield {
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(33, 33, 33, 0.15);
  padding: 8px 12px;
}

.p-botones-form {
  padding: 0px 37px 0px 37px;
}

.boton-notas-form {
  width: 151px;
  height: 40px;
}

.ml-24 {
  margin-left: 24px!important;
}

.h-36 {
  height: 36px;
}

.solicitar-act {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.titulo-modal {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #212121;
}

.disc-style {
  display: list-item!important;
  list-style: disc;
}

.center-list {
  display: flex;
  justify-content: center;
}

.boton-solicitar {
  width: 100%;
  height: 48px;
}

.solicitar-autorizacion {
  height: 36px;
  width: 287px;
}

.center-botton {
  display: flex!important;
  margin-left: auto!important;
  margin-right: auto!important;
  width: auto!important;
}

.mY-17 {
  margin-top: 17px;
  margin-bottom: 17px!important;
}

.p-absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

.bkg-status {
  background: #F4F4F4;
  padding: 10px 16px;
}

.rechazo-select {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.0)!important;
}

.no-padding-top {
  padding-top: 0px!important;
}

button.disabled-icon {
  background-color: rgba(0, 0, 0, 0.0) !important;
}

.bottom-gpo-buttons {
  position: absolute;
  bottom: 10px;
  margin-left: 45%;
  margin-right: 50%;
  display: flex;
}

.fix-crm {
  border-color: white;
}

.hw-100 {
  width: 100%;
  height: 100%;
}

.overflow-scroll {
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer;
}

.profile-photo-img {
  object-fit: cover;
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
@media (max-width: 450px) {

    /* //CabeceraContratante// */
    .padding-top-tags-cabecera {
        margin-top: 31px !important;
    }

    .w-25 {
        width: 25%;
    }

    .aseguradoAdicionalesStyle {
        background-color: #F2FAFC !important;
        padding: 5%;
    }

    .bg-comparacion-planes-mobile {
        background-color: #f5f5f5;
    }

    .bg-white-comparacion-planes-mobile {
        background-color: #fff;
        padding: 1rem;
    }

    .cont-inputs-comparar {
        background-color: #fff;
        border-radius: 8px;
    }

    .padding-img-aseguradora-comparacion {
        padding: 4% 12% 0%;
    }

    .pl-4-mobile {
        padding-left: 4%;
    }

    .pr-costo-n {
        -webkit-padding-start: 38%;
                padding-inline-start: 38%;
    }

    .pl-5-mobile {
        padding-left: 15%;
    }

    .pr-cambiar {
        -webkit-padding-start: 32%;
                padding-inline-start: 32%;
    }

    .pr-5-mobile {
        padding-right: 5% !important;
    }

    .m-2-0-mobile {

        margin: 2% 0%;

    }

    .m-5-mobile {
        margin: 5% !important;
    }

    .mb-4-mobile {
        margin-bottom: 4% !important;
    }

    .ml-6-mobile {
        margin-left: 6%;
    }

    .ml-17-mobile {
        margin-left: 17% !important;
    }

    .ml-26-mobile {
        margin-left: 26%;
    }

    .ml-35-mobile {
        margin-left: 35%;
    }

    .ml-40-mobile {
        margin-left: 40%;
    }

    .ml-48-mobile {
        margin-left: 48%;
    }

    .mr-10-mobile {
        margin-right: 10% !important;
    }

    .pt-20px-mobile {
        padding-top: 20px !important;
    }

    .pt-2-mobile {
        padding-top: 2%;
    }

    .pt-15px-mobile {
        padding-top: 15px !important;
    }

    .display-flex-mobile {
        display: flex;
    }

    .titulo-conoce-mas-planes-mobile {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        margin: 16% !important;
    }

    .p-conoce-mas-planes-mobile {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin: 2% 9%;
    }

    .txt-ca {
        font-size: 15px;
        text-align: left;
        color: #8f8f8f;
        margin: 10% 5% 1% 10%;
    }

    .txt-cdad-anual {
        font-size: 20px;
        text-align: left;
        color: #212121;
    }

    .txt-msi {
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
        color: #424242;
    }

    .contenido-tarjetas {
        display: flex !important;
        width: 365px;
        height: 255px;
        position: relative;
        margin-left: 7% !important;
        margin-top: 10%;
    }

    .btn-elegir {
        position: absolute;
        left: -15px;
        top: -50px;
        width: 152px;
        height: 56px;
        border-radius: 8px;
    }

    .display-block-mobile {
        display: block !important;
    }

    .hideOnMobile {
        display: none !important;
    }

    .img-aseguradoras {
        width: 70%;
        height: 50%;
        margin: 1%;
    }

    .w-55-mobile {
        width: 55%;
    }

    .position-img-aseguradora-comparacion {
        position: absolute;
        top: 29%;
        left: 80%;
    }

    .position-txt-aseguradora-comparacion {
        position: absolute;
        top: 35%;
        left: 80%;
    }

    .vl-amarilla-titulo-comparar {
        border: 4.15px solid #ffd60e;
        border-bottom: none;
        border-top: none;
        border-left: none;
        height: 38px;
        margin-top: 5% !important;
        margin-right: 0%;
        margin-left: 4%;
    }

    .vl-tarjetas {
        height: 95px !important;
        margin-top: 3% !important;
        margin-right: 25px !important;
    }

    .vl-titulo {
        margin: 1% 4% !important;
        height: 85px !important;
    }

    .w-90 {
        width: 90%;
    }

    .txt-2 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }

    .txt-listo-titulo {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-align: left;
    }

    .p-comparar-beneficios-aseguradoras {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: #8f8f8f;
        margin: 0 0 12px 0;
    }

    .titulo-comparar-beneficios-aeguradoras {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin: 0;
    }

    .txt-comparar-cifras-beneficios {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
    }

    .align-left {
        justify-content: start;
    }

    .align-right {
        justify-content: end;
    }

    .txt-costo-anual-comparar {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #8f8f8f;
    }

    .txt-cifra-costo-anual-comparar {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.5px;
        text-align: left;
    }

    .bnt-loQuiero-comparar {
        width: 150px !important;
        height: 50px !important;
        background-color: #039ecc !important;
        color: #fff !important;
    }

    .contenedorAvisosImportantes {
        width: 75% !important;
        height: 140px !important;
        margin-top: 5% !important;
   
    }

    .contenidoAvisosImportantes {
        padding: 0px 35px !important;
        font-size: 13px !important;
    }

    .tituloAvisosImportantes {
        text-align: center;
        font-size: 15px;
    }

    .espaciadoAvisos {
        height: 120px !important;
    }
}

@media (min-width: 800px) {
    .showOnlyOnMobile {
        display: none !important;
    }

    .aseguradoAdicionalesStyle {
        background-color: #e8f6fa !important;
        min-height: 57px;
        padding-top: 15px;
    }
}


@media (min-width: 425px) {
    @media (max-width: 769px) {
        .mediasQuerysGuias {
            background-color: red !important;
        }

        .hideOnTablet {
            display: none !important;
        }

        .mt-31-tags {
            margin-top: 0%;
        }

        .showOntablet {
            display: flex;
        }

        .with-332-paper {
            width: 332px;
        }

        .paddingLeft-tarjetas {
            padding-left: 7px;
        }

        .displayNoneTablet {
            display: none;
        }

        .display-block-tablet {
            display: block !important;
        }

        .padding-tarjetas-tablet {
            padding: 0% !important;
        }

        .w-235-tablet {
            width: 235px !important;
        }

        .with-304-tablet {
            width: 304px !important;
        }

        .jutify-content-sw {
            justify-content: space-between !important;
        }

        .bg-white-tablet {
            background-color: #fff !important;
            padding: 2%;
            border-radius: 8px;
        }
    }
}
@media (max-width: 359px)  {
    .btn-ec-mobile {
        background-color: #fff;
        border: 2px solid #039ECC;
    }
}
@media (min-width: 391px) and (max-width: 424) {
    .btn-ec-mobile {
        background-color: #fff;
        border: 2px solid #039ECC;
    }
}


@media (max-width: 424px) {
    .mediasQuerysGuias {
        background-color: yellow !important;
    }
    .espaciosTitularCabecera{
        margin:30px 0 0 0 !important
    }

    .mt-31-tags {
        margin-top: 31px;
    }

    .marginRight-8px {
        margin-right: 8px;
    }

    .marginLeft-8px {
        margin-left: 8px;
    }

    .btn-ec-mobile {
        border-radius: 8px;
        height: 48px;
        padding: 1%;
        text-align: center;
        border: #039ECC solid 1px;
        background-color: #FFF;
    }

    .flex-end-on-desktop {
        display: flex;
        justify-content: flex-end;
      }

    .flex-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hideOnMobile-2 {
        display: none;
    }

    .showOnlyOnMobile-2 {
        display: block !important;
    }

    .mr-8-mobile {
        margin-right: 8% !important;
    }

    .pt-21-modal {
        padding-top: 21%;
    }

    .bg-cabecera-mobile {
        background-color: #F4F4F4 !important;
        justify-content: center !important;
    }
}

.quotation-details-container {
    background-color: #F2FAFC;
    border-radius: 6px;
    justify-content: center;
    padding: 1rem 0;
}

@media (max-width: 360px) {
    .quotation-details-container {
        background-color: transparent;
    }

    .flex-center-on-mobile {
        justify-content: center;
    }
}

@media (min-width: 770px) {
    .mediasQuerysGuias {
        background-color: blue;
    }

    .mt-31-tags {
        margin-top: 31px;
    }

    .hideOnDesktop {
        display: none;
    }
}



.espaciosCabeceraTitular {
    padding:24px 0 20px 37px 
}

.espaciadoAvisos {
    height: 140px;
    margin-bottom: 3%;
}

.tituloAvisosImportantes {
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    margin-top: 10px;
}

.contenidoAvisosImportantes {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding: 0px 20px;
}

.contenedorAvisosImportantes {
    background-color: rgb(242, 250, 252);
    color: #8f8f8f;
    border-radius: 6px;
    width: 90%;
    height: 150px;
    margin-top: 15%;
    padding: 0 0 3% 0;   
}

@media(max-width: 799px){
    .etiqueta-beneficios-adicionales{
        margin-left:0 !important;
    }
}

.texto-etiqueta-banderin {
    font-size: 15px;
}

.cabecera-coberturas {
    height: 210px;
}

.align-self-end {
    align-self: end;
}

.ico-tooltip {
    max-width: 30px;
}

.img-aseguradoras {
    width: 80%;
    height: 60px;
    margin: 1%;
}

.container-planes {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 2%;
    /*top: -650px*/
}

.txt-cdad-anual {
    font-size: 20px;
    font-weight: 700;
    color: #212121;
}

.mw-container {
    max-width: 1140px !important;
}

.mw-contenido-paper {
    max-width: 220px !important;
}

.mw-contenido-coberturas {
    max-width: 280px !important;
    align-self: self-end;
    margin-bottom: 40px !important;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-2 {
    margin-bottom: 2%;
}

.p-7 {
    padding: 7%;
}

.p-5 {
    padding: 5%;
}

.p-1 {
    padding: 1%;
}

.p-11 {
    padding: 11%;
}

.pl-8 {
    padding-left: 8%;
}

.pl-10 {
    padding-left: 10%;
}

.pl-11 {
    padding-left: 11%;
}

.pl-28 {
    padding-left: 28%;
}

.pl-31 {
    padding-left: 31%;
}

.pl-33 {
    padding-left: 33%;
}

.pl-50 {
    padding-left: 50%;
}

.pl-32 {
    padding-left: 32%;
}

.mt-1r {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2% !important;
}

.mt-4 {
    margin-top: 4% !important;
}

.mt-14 {
    margin-top: 14%;
}

.mt-15 {
    margin-top: 14%;
}

.mt-20 {
    margin-top: 20%;
}

.mr-30 {
    margin-right: 30%;
}

.ml-12 {
    margin-left: 12% !important;
}

.ml-60 {
    margin-left: 60% !important;
}

.ml-90 {
    margin-left: 90%;
}

.space-between-centered {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.txt-2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4px;
}

.txt-resultados {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #039ecc;
    margin-top: 20%;
}

.txt-titular {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.txt-info-titular {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.txt-informacion-cliente {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.txt-coberturas {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.txt-principal-cobertura {
    margin-top: 100%;
    margin-bottom: 28%;
}

.txt-ca {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
}

.txt-msi {
    display: none;
}

.txt-cdad1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: center;
}

.txt-cdad2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-cdad3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-porsentaje {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.text-center {
    text-align: center;
}

.text-lowercase {
    text-transform: lowercase;
}

.font-weight-700 {
    font-weight: 700;
}

.txt-cdad4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-cdad5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-pd {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.txt-nhc {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.contenido-tarjetas {
    width: 200px;
    border-radius: 6px;
    margin: 5% 0 5% 0;
}

.cont-bnts {
    z-index: 10;
}

.btn-elegir {
    height: 48px;
    width: 180px;
    border-radius: 10px;
    padding: 16px;
    background-color: #039ecc !important;
    color: #fff;
}

.btn-comparar {
    height: 48px;
    width: 180px;
    border-radius: 10px;
    background-color: #fff !important;
    color: #039ecc !important;
}

.hiddeOnMobile {
    display: block !important;
}

.regresar-opciones {
    text-decoration: none;
    color: #039ecc;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.color-azul-a {
    color: #039ecc;
}

.btn-compartir-mobile {
    width: 100%;
    height: 56px;
    background-color: #f5fbfd;
    padding: 0;
    border-radius: 0%;
    margin-bottom: 5%;
}

.txt-principal-modal {
    text-align: center;
}

.input-fecha-modal {
    width: 450px !important;
}

.btn-cancelar-modal {
    width: 30%;
    height: 48px;
    border-radius: 10px;
    color: #039ecc;
    border: 1px solid #039ecc !important;
    background-color: #fff;
}

.btn-guardar-modal {
    width: 30%;
    height: 48px;
    border-radius: 10px;
    border: none !important;
    background-color: #039ecc !important;
    color: #fff !important;
}

.tooltips {
    height: 95px;
    width: 428px;
    border-radius: 8px;
    background-color: #ebf8fb;
}

.vl-titulo {
    border: 4.15px solid #ffd60e;
    border-bottom: none;
    border-top: none;
    border-left: none;
    height: 49px;
    margin-top: 3% !important;
    margin-right: 1%;
}

.bnt-enviarCotizacion {
    padding: 0;
    margin-bottom: 1%;
}

.btn-nuevaCotizacion {
    border: 1px solid #039ecc;
    color: #039ecc;
    background-color: #fff;
    border-radius: 10px;
}

.a-btn-irDC {
    text-decoration: none;
    color: #fff;
}

.btn-cancelar-modalNC {
    height: 48px;
    width: 151px;
    border-radius: 10px;
    padding: 16px;
    background-color: #fff !important;
    color: #039ecc !important;
    border: 1px solid #039ecc !important;
    box-shadow: none !important;
}

.btn-aceptar-modalNC {
    height: 48px;
    width: 151px;
    border-radius: 10px;
    padding: 16px;
    background-color: #039ecc !important;
    color: #fff !important;
    border: 1px hidden #039ecc !important;
    box-shadow: none !important;
}

.m-btn-modalNC {
    margin: 5% 5% 0% 4%;
}

.p-modalNC {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.titulo-modalNC {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.25px;
    text-align: left;
}

.btn-enviar-modalEC {
    width: 100%;
    height: 48px;
    border-radius: 10px;
    background-color: #039ecc !important;
    color: #fff !important;
    border: 1px hidden #039ecc !important;
    box-shadow: none !important;
    text-transform: uppercase;
}

.m-btn-modalEC {
    margin: 10% 5% 0% 4%;
}

.styleTextAseguradosAdicionales {
    font-weight: 600;
}

.horizontalDividerAsegurados {
    left: 23.19%;
    right: 23.33%;
    top: 32.67%;
    bottom: 67.33%;
    opacity: 0.4;
    border-bottom: 1px solid #595959;
}

.txt-contenido-asegurados-adicionales {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.txt-titulo-asegurados-adicionales {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.paper-mobile {
    max-width: 400px;
    height: 100%;
    margin: 0 2% 0 2%;
    margin-top: 5%;

}

.margen-paper-mobile {
    padding-left: 10%;
    padding-top: 5%;
}

.divider-paper-mobile {
    margin-left: -5% !important;
    height: 94px !important;
    margin-top: -8px !important;
}

.margin-btns-mobile {
    margin: 1% 0% !important;
    padding-left: 4% !important;
}

.btn-comparar-mobile {
    width: 136px;
    height: 56px;
    border-radius: 8px !important;
    color: #039ecc !important;
    border: 1px solid #039ecc !important;
}

.btn-loQuiero-mobile {
    width: 136px;
    height: 56px;
    border-radius: 8px !important;
    background-color: #039ecc !important;
    border: none;
    color: #fff !important;
}

.btn-nueva-cotizacion-mobile {
    width: 344px;
    height: 48px;
    border-radius: 8px !important;
    color: #039ecc !important;
    border: 1px solid #039ecc !important;
    background-color: #fff !important;
    margin: 5% 0% !important;
}

.txt-cdad-planes {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: right !important;
}

.pt-2 {
    padding-top: 1.5rem;
}

.titulo-compara-beneficios {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.25px;
    text-align: left;
    margin-left: 12px;
}

.divider {
    width: 144px;
    border: 1px solid #c7c7c7;
}

.tarjetas-tamano {
    height: 645px;
}

.mw-19-cabezera {
    max-width: 19% !important;
}

.marginTop-152 {
    margin-top: 152px;
}
.w-bgWhite {
  width: 750px;
}

.zIndex-15 {
  z-index: 20 !important;
}

.title-beneficios {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0 0 0 4px;
}

.logo-insurer-mobile {
  width: 103px;
}

.card-selected-insurer {
  background-color: #ffff;
  border-radius: 12px;
  margin: 1.5rem 0.5rem;
  padding: 1rem;
}

@media (max-width: 360px) {
  .logo-insurer-mobile {
    width: 75px;
  } 
}

@media (min-width: 425px) {
  @media (max-width: 890px){
    .cabecera-beneficios {
      margin-bottom: 8px;
    }
    .valor-beneficios {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */
      text-align: right;
      color: #000000;
    }
    .valor-beneficios-aux {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */
    }
    .w-95-mobile {
      width: 95% !important;
    }
    .pl-0-mobile {
      padding-left: 0% !important;
    }
    .pl-0-mobile {
      padding: 0% !important;
    }
    .ml-1 {
      margin-left: 1% !important;
    }
    .personaliza-tu-plan-titulo {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: 0.5px;
      text-align: left;
    }
    .p-personaliza-tu-plan-descripcion {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: left;
    }
    .padding-1 {
      padding: 1%;
    }
    .img-goBack-mobile {
      width: 2%;
      margin: 0% 3%;
    }
    .vl-amarilla-titulo {
      top: -23px;
    }
    .cont-tarjetas-position {
      position: relative !important;
      margin-left: 6% !important;
      top: 0 !important;
      right: 0 !important;
    }
    .margin-title-beneficios-extras {
      margin: 1% 5% !important;
    }
    .w-bgWhite {
      width: 90% !important;
    }
    .hideOnMobile {
      display: none !important;
    }
    .cont-beneficios {
      padding: 1rem;
    }
    .p-beneficios {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px !important;
      letter-spacing: 0px;
      text-align: justify;
      padding-right: 3% !important;
    }

    .bg-beneficios-coaseguro {
      display: flex;
      text-align: center;
      justify-content: center;
      padding: 2%;
    }
    .txt-titulo-coberturas-extras-mobile {
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 15px !important;
      letter-spacing: 0em;
      text-align: left;
    }
    .txt-contenido-coberturas-extras-mobile {
      color: #595959;
    }
    .bg-azul5 {
      background-color: #f2fafc !important;
    }
  }
}
@media (min-width: 890px) {
  @media (max-width: 996px){
    .p-beneficios{
      font-size: 9px !important;
    }
  }}
@media (min-width: 996px) {
  @media (max-width: 1115px){
    .p-beneficios{
      font-size: 10px !important;
    }
  }}

@media (max-width: 450px) {
  .cabecera-beneficios {
    margin-bottom: 8px;
  }
  .valor-beneficios {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    text-align: right;
    color: #000000;
  }
  .valor-beneficios-aux {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
  }
  .w-95-mobile {
    width: 95% !important;
  }
  .pl-0-mobile {
    padding-left: 0% !important;
  }
  .pl-0-mobile {
    padding: 0% !important;
  }
  .ml-1 {
    margin-left: 1% !important;
  }
  .personaliza-tu-plan-titulo {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .p-personaliza-tu-plan-descripcion {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .padding-1 {
    padding: 1%;
  }

  .img-goBack-mobile {
    height: 12px;
    margin-right: 1rem;
  }

  .vl-amarilla-titulo {
    top: -23px;
  }
  .cont-tarjetas-position {
    position: relative !important;
    margin-left: 6% !important;
    top: 0 !important;
    right: 0 !important;
  }
  .margin-title-beneficios-extras {
    margin: 1% 5% !important;
  }
  .w-bgWhite {
    width: 90% !important;
  }
  .hideOnMobile {
    display: none !important;
  }
  .cont-beneficios {
    padding: 1rem;
  }

  .p-beneficios {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px !important;
    letter-spacing: 0px;
    text-align: justify;
    padding-right: 3% !important;
  }
  .bg-beneficios-coaseguro {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 2%;
  }
  .data-flag-feature {
    margin: 0;
  }
  
  .title-beneficios {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }
  .txt-titulo-coberturas-extras-mobile {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 15px !important;
    letter-spacing: 0em;
    text-align: left;
  }
  .txt-contenido-coberturas-extras-mobile {
    color: #595959;
  }
  .bg-azul5 {
    background-color: #f2fafc !important;
  }
}

@media (min-width: 800px) {
  .showOnlyOnMobile {
    display: none !important;
  }
  .pl-10 {
    padding-left: 10% !important;
  }
}

.half-circle-left {
  width: 20px;
  background-color: #f4f4f4;
  border-bottom-right-radius: 110px;
  border-top-right-radius: 110px;
  border: 10px solid #f4f4f4;
  border-right: 0px;
  content: "";
}

.half-circle-right {
  width: 20px;
  background-color: #f4f4f4;
  border-bottom-left-radius: 110px;
  border-top-left-radius: 110px;
  border: 10px solid #f4f4f4;
  border-right: 0px;
  content: "";
}

.list-estilos-plant-personalizado {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8f8f8f;
}

.titulo-cabecera-aviso-importante {
  color: #8f8f8f;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

.txt-aviso-importante {
  color: #595959;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.input-no-colaborador {
  background-color: #ffffff;
}

.encabezado-aviso-colaborador {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #595959;
}

.sub-cabecera-aviso-colaborador {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.txt-cabecera-aviso-colaborador {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #304659;
}

.separador-detalles-cotizacion {
  border: none;
  border-style: dashed #f4f4f4;
  border-top: 1px;
  background-color: #f4f4f4;
  height: 1px;
  width: 80%;
}

.text-auxiliar-cupones {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #039ecc;
  margin-bottom: 15px;
}

.cajon-superior-detalle {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.text-cupones-detalle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.vl-titulo-personalizar {
  border: 4.15px solid #ffd60e;
  border-bottom: none;
  border-top: none;
  border-left: none;
  height: 55px;
  margin-top: 2% !important;
  margin-right: 1%;
}

.vl-amarilla-titulo {
  border-bottom: none;
  border-top: none;
  border-left: none;
  height: 38px;
  margin-top: 5% !important;
  width: 0;
  margin: 0;
  position: absolute;
}

.ml-2 {
  margin-left: 2%;
}

.ml-1 {
  margin-left: 1% !important;
}

.ml-3 {
  margin-left: 3%;
}

.w-1 {
  width: 1%;
}

.pb-2 {
  padding-bottom: 2% !important;
}

.pt-2 {
  padding-top: 2%;
}

.m-0 {
  margin: 0;
}

.mtb-1 {
  margin: 1% 0%;
}

.ml-5 {
  margin-left: 5%;
}

.ml-11 {
  margin-left: 11%;
}

.mr-3 {
  margin-right: 3%;
}

.mr-7 {
  margin-right: 7%;
}

.m-10 {
  margin: 10%;
}

.p-5 {
  padding: 5%;
}

.mt-40 {
  margin-top: 40% !important;
}

.fondoStyle {
  min-height: 100vh;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #f4f4f4;
}

.bg-white {
  background-color: #ffff;
  border-radius: 12px;
  margin: 1.5rem 0.5rem;
  padding: 0.5rem;
}

.bg-beneficios {
  background-color: rgba(12, 55, 113, 0.05);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

div.bg-beneficios::after, div.bg-beneficios::before {
  content: ' ';
  background-color: #FAFBFC;
  width: 16px;
  height: 16px;
  display: inline-block;
}

div.bg-beneficios::after {
  margin-left: auto;
  -webkit-transform: translate(8px, 0) rotate(45deg);
          transform: translate(8px, 0) rotate(45deg);
}

div.bg-beneficios::before {
  margin-right: auto;
  -webkit-transform: translate(-8px, 0) rotate(45deg);
          transform: translate(-8px, 0) rotate(45deg);
}

.p-beneficios {

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14.63px;
  letter-spacing: 0px;
  text-align: left;
  padding-right: 12%;
}

.sub-txt-beneficios {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: center;
}

.cont-beneficios {
  border-radius: 6px;
  background-color: rgba(12, 55, 113, 0.02);
  margin-top: 2%;
}

.cont-beneficios-extras {
  height: 402px;
}

.coinsurance-cap-form-control {
  width: 230px;
}

@media (max-width: 360px) {
  .coinsurance-cap-form-control {
    width: 100%;
  }
}

.bg-grey {
  background-color: rgba(12, 55, 113, 0.02);
}

.banderin-beneficios {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFD60E;
  font-size: 16px;
  font-weight: 700;
  margin: 8px 0;
  height: 29px;
  border-radius: 0 14.5px 14.5px 0;
  width: 332px;
  -webkit-transform: translate(-35px, 0);
          transform: translate(-35px, 0);
}

.banderin-beneficios::before {
  content: ' ';
  background-color: #f4f4f4;
  display: inline-block;
  height: 20.5px;
  width: 20.5px;
  -webkit-transform: translate(-31.5px, 0) rotate(45deg);
          transform: translate(-31.5px, 0) rotate(45deg);
}

@media (max-width: 360px) {
  .banderin-beneficios {
    width: 100%;
    -webkit-transform: translate(0,0);
            transform: translate(0,0);
  }

  .banderin-beneficios::before {
    background-color: white;
    -webkit-transform: translate(-21.5px, 0) rotate(45deg);
            transform: translate(-21.5px, 0) rotate(45deg);
  }
}

.bg-beneficios-adic {
  background-color: #e8f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.bg-beneficios-adic::after, div.bg-beneficios-adic::before {
  content: ' ';
  background-color: #FAFBFC;
  width: 16px;
  height: 16px;
  display: inline-block;
}

div.bg-beneficios-adic::after {
  margin-left: auto;
  -webkit-transform: translate(8px, 0) rotate(45deg);
          transform: translate(8px, 0) rotate(45deg);
}

div.bg-beneficios-adic::before {
  margin-right: auto;
  -webkit-transform: translate(-8px, 0) rotate(45deg);
          transform: translate(-8px, 0) rotate(45deg);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-tarjeta-cotizacion {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 3%;
}

.subTxt-tarjeta-cotizacion {
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.txt-plan-esencial {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.txt-monto-plan {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: right;
}

.cont-tarjetas-position {
  position: absolute;
}

.pt-15rem {
  padding-top: 1.5rem;
}

.regresar-opciones {
  margin-left: 17px;
  text-decoration: none;
  color: #039ecc;
}

.bnt-personalizar {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #039ecc !important;
  margin-bottom: 4% !important;
  margin-top: 2%;
}

.btn-contratar {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  background-color: #039ecc !important;
  color: #fff !important;
}

.txt-contenido-coberturas-extras {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.txt-titulo-coberturas-extras {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.divider-personalizar {
  margin: 5% 0% !important;
  border-style: dashed !important;
}

.margin-personaliza-plan-title {
  margin: 2% 0% 0% 1%;
}

.p-etiquetavalor {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #212121;
}

.hr {
  width: 68%;
  height: 0;
  background: #f4f4f4;
  opacity: 0.4;
  border: 1px dashed #595959 !important;
}

.hr-dos {
  width: 100%;
  height: 0;
  background: #f4f4f4;
  opacity: 0.4;
  border: 1px dashed #595959 !important;
}

.hr-hidden {
  clear: both;
  visibility: hidden;
}

.boton-flotante {
  height: 56px;
  border-radius: 8px;
  background-color: #039ecc !important;
  color: #fff !important;
}

.text-ya {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */
  letter-spacing: 0.5px;
  /* Text/H1 */
  color: #212121;
  margin-left: 17px;
}

.text-dos {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-left: 17px;
}

.linea-amarilla {
  position: relative;
}

.linea-amarilla img {
  left: 0;
  top: 0;
  position: absolute;
}

.linea-amarilla-dos {
  border-left-color: #ffd60e;
  border-left-style: solid;
  border-left-width: 5px;
}

.nombre {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* Text/H1 */
  color: #212121;
  margin-left: 17px;
}

.titular {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* Text/H1 */
  color: #212121;
  margin-left: 17px;
}

.fecha {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* Text/Body */
  color: #424242;
}

.frame-cuestionario {
  border-radius: 12px;
  height: 100%;
  width: 100%;
  box-shadow: 4px 6px 16px #c7c7c7;
  background-color: #ffffff;
}

.frame-cuestionario-detalle {
  padding: 25px 55px;
}

@media (max-width: 768px) {
  .frame-cuestionario-detalle {
    padding: 1rem 1.5rem;
  }
}

.padding-preguntas {
  padding-left: 28px;
  padding-right: 28px;
}

.text-preguntas {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  color: #000000;
}

.text-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #000000;
}

@media (max-width: 426px) {
  .frame-cuestionario {
    box-shadow: none;
  }
  .padding-preguntas {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.background-acordeon {
  background-color: #c0e6f2 !important;
  border-radius: 0px !important;
}

.background-form-acordeon {
  background-color: #f2fafc !important;
  padding: 8px 16px;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}

button.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.inline {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.no-margin-accordeon > .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.boton-mobile {
  width: 340px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
